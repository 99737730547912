import { Controller } from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = [
    "copyText",
    "copyTextButton",
    "code",
    "codeError",
    "taxisForm",
    "mydataForm",
    "taxisInfo",
    "myDataInfo",
    "taxisSubmit",
    "username",
    "password",
    "subject",
    "body",
    "attachment"
  ]

  connect() {
    this.removeLoader()
    this.showCorrectform()
    this.initCopyTextButton()
    this.handleLoadingBanner()
    this.inputValidations()
  }

  handleLoadingBanner() {
    if (this.element.dataset.loadingBanner == "true") {
      const interval = setInterval(() => {
        fetch(this.element.dataset.url)
          .then((response) => response.json())
          .then((data) => {
            if (data.loaded) {
              notification("success", this.element.dataset.doneMsg)
              clearInterval(interval)
              this.element.remove()
              document.getElementById("importing_banner").remove()
            }
          })
      }, 15000)
    }
  }

  removeLoader() {
    const loadingEl = document.querySelector(".page-loader")
    if (loadingEl) {
      loadingEl.remove()
    }
  }

  togglePassword() {
    var x = document.getElementById("aade_password");
    var y = document.getElementById("togglePasswordIcon");
    if (x.type === "password") {
      x.type = "text";
      y.classList.add('fa-eye')
      y.classList.remove('fa-eye-slash')
    } else {
      x.type = "password";
      y.classList.remove('fa-eye')
      y.classList.add('fa-eye-slash')
    }
  }

  //<-- old referral method deprecation?
  copyText() {
    const button = this.copyTextButtonTarget
    navigator.clipboard.writeText(this.copyTextTarget.value)
    button.innerText = button.dataset.pressedText
    this.copyTextTarget.classList.add("bg-success", "text-inverse-success")
    fetch("/referrals/referral_code_copied_email", {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    })
    setTimeout(() => {
      button.innerText = button.dataset.text
      button.blur()
      this.copyTextTarget.classList.remove("bg-success", "text-inverse-success")
    }, 3000)
  }

  initCopyTextButton() {
    if (this.hasCopyTextButtonTarget) {
      const button = this.copyTextButtonTarget
      button.innerText = button.dataset.text
    }
  }

  createReferral() {
    const code = this.codeTarget.value
    const url = this.codeTarget.dataset.url

    if (code.length == 10) {
      fetch(url + `?code=${code}`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.csrfToken()
        }
      }).then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.codeTarget.classList.add("bg-success", "text-inverse-success")
            this.codeTarget.readOnly = true
          } else {
            this.codeTarget.classList.add("bg-danger", "text-inverse-danger")
            this.codeErrorTarget.hidden = false
            setTimeout(() => {
              this.codeTarget.classList.remove("bg-danger", "text-inverse-danger")
              this.codeErrorTarget.hidden = true
            }, 3000)
          }
        })
    }
  }
  // old referral method deprecation? -->

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  pageLoad(message = "Loading...") {
    const loadingEl = document.createElement("div")
    document.body.prepend(loadingEl)
    loadingEl.classList.add("page-loader", "flex-column", "bg-dark", "bg-opacity-25", "align-items-center",
      "justify-content-center")
    loadingEl.innerHTML = `
      <span class="spinner-border text-primary" role="status"></span>
      <span class="text-gray-800 fs-6 fw-semibold mt-5">${message}</span>
    `
    loadingEl.style.display = "flex"
  }

  closePageLoader() {
    const loadingEl = document.querySelector(".page-loader")
    if (loadingEl) {
      loadingEl.remove()
    }
  }

  showCorrectform() {
    if (this.data.get('selectedSource')) {
      if (this.data.get('selectedSource') == 'aade') {
        this.showTaxisForm();
      } else {
        this.showMydataForm();
      }
    } else if (window.location.href.includes("edit_my_data_settings")) {
      this.showMydataForm();
    } else {
      this.showTaxisForm();
    }
  }

  showMydataForm() {
    if (this.hasTaxisFormTarget) this.taxisFormTarget.hidden = true
    if (this.hasTaxisInfoTarget) this.taxisInfoTarget.hidden = true

    if (this.hasMydataFormTarget) this.mydataFormTarget.hidden = false
    if (this.hasMyDataInfoTarget) this.myDataInfoTarget.hidden = false

  }

  showTaxisForm() {
    if (this.hasTaxisFormTarget) { this.taxisFormTarget.hidden = false }
    if (this.hasTaxisInfoTarget) { this.taxisInfoTarget.hidden = false }
    if (this.hasMydataFormTarget) { this.mydataFormTarget.hidden = true }
    if (this.hasMyDataInfoTarget) { this.myDataInfoTarget.hidden = true }
  }


  handleSubmit() {
    this.taxisSubmitTarget.disabled = this.usernameTarget.value.length < 3 || this.passwordTarget.value.length < 3
  }

  changeTemplate(event) {
    const target = event.target
    const option = target.options[target.selectedIndex]

    if (option.value == "new") {
      Turbolinks.visit(option.dataset.url)
    } else {
      const subject = option.dataset.subject
      const body = option.dataset.body
      const locale = option.dataset.locale
      this.subjectTarget.value = subject
      this.bodyTarget.value = body
      document.getElementById("attachment_locale").value = locale
      this.attachmentTargets.map((elem) => {
        elem.href = elem.href.split("pdf_locale=")[0] + `pdf_locale=${locale}`
      })
    }
  }

  inputValidations() {
    const forms = document.querySelectorAll('.mydata-form')
    Array.from(forms)
      .forEach(function (form) {
        var invalidInputs = form.querySelectorAll('.is-invalid');

        invalidInputs.forEach(function (input) {
          // Remove the 'is-invalid' class after 3 seconds for each invalid input
          setTimeout(function () {
            input.classList.remove('is-invalid');
          }, 3000);
        });
      })
  }

}
