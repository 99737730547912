import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "phoneNumber", "phoneCountry", "submit" ]

  connect() {
    new bootstrap.Modal(this.element).toggle()
  }

  checkPhone() {
    if ( this.phoneCountryTarget.value == "gr" ) {
      return this.phoneNumberTarget.value.length == 10
    } else {
      return this.phoneNumberTarget.value.length > 5
    }
  }

  handleSubmit() {
    this.submitTarget.disabled = !this.checkPhone()
  }

}