import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    print()
  }

  printReceiptPage() {
    print()
  }

  // csrfToken() {
  //   return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  // }

}
