// Implement parallax-js with stimulus controller
// Otherwise it doesn't load in combination with turbo links

import { Controller } from "@hotwired/stimulus"
window.Parallax = require("parallax-js");

export default class extends Controller {

  static targets = ['parallax']

  connect() {
    this.parallaxInit()
  }

  parallaxInit() {
    let items = this.parallaxTargets

    for (let i = 0; i < items.length; i++) {
      let parallaxInstance = new Parallax(items[i])
    }

  }
}
