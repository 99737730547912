import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "warning" ]

  handleLengthWarning(e) {
    const tradeName = e.target.value
    const warning = this.warningTarget
    const warningText = warning.dataset.text
    warning.hidden = true
    if ( tradeName.length > 75 ) {
      warning.innerText = warningText.replace("XX", "75")
      warning.hidden = false
    }
  }

}
