import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lineOption"]

  connect() {
    this.handleLineOptions()
  }

  handleLineOptions() {
    const selection = document.querySelector("input[name='proposal[lines_or_sections]']:checked").value
    this.lineOptionTargets.map(option => option.disabled = true)
    if ( selection == 'all_lines' ) {
      this.lineOptionTargets.map(option => option.disabled = false)
    }
  }

  checkAllCheckboxes() {
    this.lineOptionTargets.map(option => option.checked = true)
  }
}
