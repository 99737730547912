import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.loadTab()
  }

  loadTab() {
    if ( this.isActive() && this.tabContentEmpty() ) {
      document.getElementById(this.tabId()).innerHTML = '<div class="spinner-border text-primary"></div>'
      fetch(this.urlValue, {
        method: "GET",
        headers: {
          "X-CSRF-Token": this.csrfToken()
        }
      })
        .then((response) => response.text())
        .then((data) => {
          document.getElementById(this.tabId()).innerHTML = data
          ;[...document.querySelectorAll(".select2")].map(el => { new SlimSelect({ select: el }) })
        })
    }
  }

  tabId() {
    return this.element.getAttribute("href").replace("#", "")
  }

  isActive() {
    return this.element.classList.contains("active")
  }

  tabContentEmpty() {
    return document.getElementById(this.tabId()).textContent.trim() == ""
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

}
