import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  followUrl(e) {
    let url = (e.target.nodeName === "TD") ?
      e.target.parentElement.dataset.url :
      e.target.dataset.url
    window.open(url, "_top");
  }
}
