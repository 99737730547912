import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["personalGeneralInfo", "businessGeneralInfo", "contactInfo", "additionalInfo"]

  connect() {
    let personal_radio = document.getElementById('account_personal')
    if (personal_radio.checked) {
      this.change_required_attr('account_city', false)
      this.change_required_attr('account_postal_code', false)
      this.change_required_attr('account_address', false)
    }
  }

  change_type(e) {
    if (e.target.value === 'business' && this.businessGeneralInfoTarget.classList.contains('d-none')) {
      this.businessGeneralInfoTarget.classList.remove('d-none')
      this.additionalInfoTarget.classList.remove("d-none")

      this.personalGeneralInfoTarget.classList.add('d-none')
      this.hasContactInfoTarget ? this.contactInfoTarget.classList.add('d-none') : false

      this.change_required_attr('account_vat_no', true)
      this.change_required_attr('account_trade_name', true)
      this.change_required_attr('account_city', true)
      this.change_required_attr('account_postal_code', true)
      this.change_required_attr('account_address', true)
      this.change_required_attr('account_first_name', false)
      this.change_required_attr('account_last_name', false)
    }

    if (e.target.value === 'personal' &&
      this.personalGeneralInfoTarget.classList.contains('d-none')) {

      this.businessGeneralInfoTarget.classList.add('d-none')
      this.additionalInfoTarget.classList.add("d-none")
      this.personalGeneralInfoTarget.classList.remove('d-none')
      this.hasContactInfoTarget ? this.contactInfoTarget.classList.remove('d-none') : false

      this.change_required_attr('account_vat_no', false)
      this.change_required_attr('account_trade_name', false)
      this.change_required_attr('account_first_name', true)
      this.change_required_attr('account_last_name', true)
      this.change_required_attr('account_city', false)
      this.change_required_attr('account_postal_code', false)
      this.change_required_attr('account_address', false)
    }

  }

  change_required_attr(id, status) {
    let text_field = document.getElementById(id)
    let label = text_field.nextElementSibling
    text_field.required = status
    label.textContent = label.textContent.replace(/\*/g, '')
    if (status == true) label.textContent += '*'
  }

  check_vat(e) {
    let url = e.target.dataset.url + `?vat=${e.target.value}&t_id=${e.target.dataset.tid}`
    this.fetch_data(e, url)
  }

  check_amka(e) {
    let url = e.target.dataset.url + `?amka=${e.target.value}&t_id=${e.target.dataset.tid}`
    this.fetch_data(e, url)
  }

  fetch_data(e, url) {
    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        "Accept": "application/json"
      }
    }).then((response) => response.json())
      .then((data) => {
        let element = e.target.closest(".form-floating")
        let alert = element.nextElementSibling
        if (data.message) {
          alert.innerHTML = data.message
        } else {
          alert.innerHTML = ""
        }
      })
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }



}
