import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  validate_form(e) {
    let email = document.getElementById('request_demo_email')
    let name = document.getElementById('request_demo_name')
    let toc = document.getElementById('request_demo_toc')
    let phone = document.getElementById('request_demo_phone')

    let valid_email = this.validate_form_fields(email)
    let valid_name = this.validate_form_fields(name)
    let valid_toc = this.validate_form_fields(toc)
    let valid_phone = this.validate_form_fields(phone)



    if (!valid_email || !valid_name || !valid_toc || !valid_phone ) {
      e.preventDefault()
      return false
    }
  }

  validate_form_fields(obj) {
    let result = true
    if (obj.type === 'checkbox') {
      if (obj.checked === false) {
        obj.nextElementSibling.classList.add('badge', 'bg-danger', 'text-dark')
        result = false
      } else {
        obj.nextElementSibling.classList.remove('badge', 'bg-danger', 'text-dark')
      }
    } else {
      if ( obj.value.trim().length === 0 || ( obj.type === 'email' && !this.validate_email(obj) ) || ( obj.type === 'tel') && this.validate_phone_number(obj) ) {
        obj.closest('div').firstElementChild.classList.add('bg-danger', 'text-light')
        result = false
      } else {
        obj.closest('div').firstElementChild.classList.remove('bg-danger', 'text-light')
      }
    }
    return result
  }

  validate_fields_on_focusout(e) {
    this.validate_form_fields(e.target)
  }

  validate_email(obj) {
    let REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return REGEX.test(obj.value)
  }

  validate_phone_number(obj) {
    let regex = /[A-Za-z]/
    return regex.test(obj.value)
  }

  subsidiary_link(e) {
    e.preventDefault()
    let target_link = e.target.dataset.jsLink

  }

}
