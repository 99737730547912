import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "type"]
  static values = {
    invoiceType: String,
    allOptions: Object,
    typesTranslations: Object
  }

  loadTypes() {
    const category = this.categoryTarget.value
    const values = this.allOptionsValue[this.invoiceTypeValue][category]

    this.typeTarget.innerHTML = ""

    values.map(value => {
      const label = (value && this.typesTranslationsValue[value]) ? this.typesTranslationsValue[value] : ""
      const option = `<option value="${value}">${label} ${value}</option>`
      this.typeTarget.innerHTML += option
    })
  }
}