import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import Sortable from 'sortablejs'

export default class extends Controller {

  static targets = [ 'editLink' ]

  connect() {
    this.initTemplatesSort()
    this.initLinksEvent()
  }

  initLinksEvent() {
    this.editLinkTargets.forEach(elem => {
      elem.addEventListener('click', (e) => this.handleLinkClicked(e))
    })
  }

  initTemplatesSort() {
    const initTemplates = document.getElementById("js-templates-sort")
    const reorderUrl = initTemplates.dataset.url
    const sortable = new Sortable(initTemplates, {
      animation: 150,
      onEnd: (event) => {
        Rails.ajax({
          url: reorderUrl,
          type: "PUT",
          dataType: "script",
          data: "ids=" + sortable.toArray()
        })
      }
    })
  }

  handleLinkClicked(e) {
    document.querySelectorAll('tr.selected-row').forEach(elem => {
      elem.classList.remove("selected-row")
    })
    const tempId = e.target.dataset.templateId
    const trElem = document.querySelector(`tr[data-id="${tempId}"]`)
    trElem.classList.toggle("selected-row")
  }

}
