import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["periodSelect", "from", "to", "dates"]
  static values = {url: String}

  periodChange() {
    const chart = Chartkick.charts["vat-chart"]
    let query = `&date_range=${this.periodSelectTarget.value}`
    if (this.periodSelectTarget.value === "custom_period") {
      this.datesTarget.hidden = false
      if (this.fromTarget.value === "" || this.toTarget.value === "") return
      query += `&from=${this.fromTarget.value}&to=${this.toTarget.value}`
    } else {
      this.datesTarget.hidden = true
    }

    if (chart) {
      chart.dataSource = this.urlValue + query;
      chart.refreshData();
    }
  }
}
