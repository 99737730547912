import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "passwordField", "button" ]

  toggleShowPassword() {
    this.buttonTarget.checked ?
      this.passwordFieldTarget.type = "text" : this.passwordFieldTarget.type = "password"
  }

  toggleShow() {
    if ( this.passwordFieldTarget.type == "password") {
      this.passwordFieldTarget.type = "text"
      this.buttonTarget.classList.add("fa-eye")
      this.buttonTarget.classList.remove("fa-eye-slash")
    } else {
      this.passwordFieldTarget.type = "password"
      this.buttonTarget.classList.remove("fa-eye")
      this.buttonTarget.classList.add("fa-eye-slash")
    }
  }
}
