import { Controller } from "@hotwired/stimulus"
import InvoiceFormController from "./invoice_form_controller"

export default class extends Controller {
  static values = {
    fullPrice: Number
  }

  initialize() {

  }

  connect() {

  }

  priceChanged(e) {
    this.changePrice(e.target)
  }

  changePrice(el) {
    let fullprice = el.dataset.fullprice
    if (fullprice) {
      let vatPrice = Number(document.getElementsByClassName('js-line-subtotal ' + el.dataset.simplifiedtype + el.dataset.num)[0].value)
      let otherSimplifiedType = el.dataset.simplifiedtype == 'vat' ? 'exc' : 'vat'
      let otherEl = document.getElementsByClassName('js-line-unit-price ' + otherSimplifiedType + el.dataset.num)[0]
      otherEl.value = Number(fullprice) - vatPrice
      // let ifc = new InvoiceFormController();
      // ifc.updatePrices()
      otherEl.dispatchEvent(new Event('custom'))
    }

  }

}
