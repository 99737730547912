import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"

export default class extends Controller {
  static targets = [ "phoneNumber", "phoneCountry", "dialCode" ]

  connect() {
    this.phoneInput = intlTelInput(this.phoneNumberTarget, {
      customContainer: "w-100",
      separateDialCode: true
    })

    this.phoneInput.setCountry(this.phoneNumberTarget.dataset.defaultCountry)
    this.setDialCodeAndCountry(this.phoneInput)

    this.phoneNumberTarget.addEventListener("countrychange", () => {
      this.setDialCodeAndCountry(this.phoneInput)
    })
  }

  setDialCodeAndCountry(input) {
    const data = input.getSelectedCountryData()
    this.phoneCountryTarget.value = data.iso2
    this.dialCodeTarget.value = data.dialCode
  }
}
