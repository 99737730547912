import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
  }

  printWindow(e) {
    e.preventDefault()
    const printTitle = document.querySelector(".js-print-title")
    document.title = printTitle.dataset.value
    if (this.isSafari()) {
      let url = window.location.href
      window.open(url, '_blank').focus();
      window.print()
      window.close()
    } else {
      window.print()
    }
  }
}
