import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productionStage", "rateType", "duration", "minimumRate", "revision"]

  connect() {
    this.handleFormInputs()
  }

  handleFormInputs() {
    if (this.hasDurationTarget && this.hasMinimumRateTarget && this.hasRevisionTarget) {
      this.durationTarget.disabled = true
      this.durationTarget.closest('.form-group').hidden = true
      this.minimumRateTarget.disabled = true
      this.minimumRateTarget.closest('.form-group').hidden = true
      this.revisionTarget.disabled = true
      this.revisionTarget.closest('.form-group').hidden = true
    }

    if ( this.productionStageTarget.value == 'shoot' ) {
      this.rateTypeTarget.value = 'per_working_day'
    } else if ( this.rateTypeTarget.value == 'flat' ) {
      if (this.hasRevisionTarget) {
        this.revisionTarget.disabled = false
        this.revisionTarget.closest('.form-group').hidden = false
      }
    } else {
      if (this.hasDurationTarget && this.hasMinimumRateTarget && this.hasRevisionTarget) {
        this.durationTarget.disabled = false
        this.durationTarget.closest('.form-group').hidden = false
        this.minimumRateTarget.disabled = false
        this.minimumRateTarget.closest('.form-group').hidden = false
        this.revisionTarget.disabled = false
        this.revisionTarget.closest('.form-group').hidden = false
      }
    }
  }
}