import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
import accounting from "accounting"

export default class extends Controller {

  static values = {
    series: Object
  }

  connect() {
    this.renderChart()
  }



  renderChart() {
    var element = document.getElementById('pos_payments_chart');


    if (!element) {
      return;
    }

    const options = {
      series: [{
        name: this.seriesValue.title,
        data: this.seriesValue.series

      }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: '100%',
        toolbar: {
          show: false
        },
        zoom: false
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            const formatted = accounting.formatMoney(value, "€", 2, ".", ",")
            return formatted
          }
        }
      },
      xaxis: {
        categories: this.seriesValue.categories,
        type: 'category',

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            fontSize: '12px'
          }
        },
        crosshairs: {
          enabled: false
        },

        tooltip: {
          enabled: false
        }
      },
      colors: ["#44BA6B", "#FE5738"]
    }



    var chart = new ApexCharts(element, options);
    chart.render();
  }


}
