import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select";

export default class extends Controller {
  static values = {newProjectUrl: String}

  connect(){
    [...this.element.querySelectorAll(".elem-select2:not(.ss-main)")].map(el => {
      if ( el.slim == null ) {
        new SlimSelect({ select: el })
      }
    })
  }

  newClicked(e){
    if ( e.target.value === "addNew" ) {
      window.location = this.newProjectUrlValue
    }
  }
}
