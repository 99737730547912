import { Controller } from "@hotwired/stimulus"
window.bootstrap = require("bootstrap")

export default class extends Controller {
  static targets = [ "bank", "bankName" ]

  connect() {
    $('[data-bs-toggle="tooltip"]').tooltip()
    this.bankChanged()
  }

  bankChanged() {
    this.bankNameTarget.hidden = this.bankTarget.value != 'other'
  }

  hideTooltip(e) {
    bootstrap.Tooltip.getInstance(e.target).hide()
  }

  ibanEval(e) {
    console.log('key pressed')
    let el = e.target
    el.value = el.value.replace(/\s/g, '')
    el.value = el.value.toUpperCase()
  }
}
