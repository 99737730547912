import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.autoDismiss()
  }

  autoDismiss() {
    [...document.querySelectorAll(".alert")].map(alert => {
      setTimeout(() => { new bootstrap.Alert(alert).close() }, 5000)
    })
  }
}
