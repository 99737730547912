import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productionStage"]

  connect() {
    this.handleDuration()
  }

  handleDuration() {
    document.querySelectorAll(".hide-on-shoot").forEach(elem => { 
      elem.querySelector("input").disabled = true
      elem.hidden = true
    })

    if ( !["", "shoot"].includes(this.productionStageTarget.value) ) {
      document.querySelectorAll(".hide-on-shoot").forEach(elem => { 
        elem.querySelector("input").disabled = false
        elem.hidden = false
      })
    }
  }
}