import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static values = {url: String}

  initialize() {
    $('[data-bs-toggle="tooltip"]').tooltip()
  }

  copyUrl(e) {
    e.preventDefault()
    fetch(this.urlValue).then(res => {
      const dummy = document.createElement('input'),
        text = res.url;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    })
    setTimeout(() => {
      $(e.target).tooltip('hide')
    }, 3000)
  }

  scrollToSection(e) {
    e.preventDefault()
    const targetId = e.target.href.split("#")[1]
    if (targetId == 'top') {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      document.getElementById(targetId).scrollIntoView({behavior: 'smooth'})
    }
  }
}
