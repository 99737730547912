import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['masterCheckbox', 'childCheckbox', 'disableOnNone']

  initialize() {
    this.masterCheckboxTarget.setAttribute("data-action", "change->mass-checkbox#masterCheckboxChanged")
    this.childCheckboxTargets.forEach(box => {
      box.setAttribute("data-action", "change->mass-checkbox#childCheckboxChanged")
    })
  }

  connect() {
    this.refreshMasterCheckbox()
    this.refreshDisabled()
  }

  masterCheckboxChanged(e) {
    this.childCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.masterCheckboxTarget.checked
    })
    this.refreshDisabled()
  }

  childCheckboxChanged() {
    this.refreshMasterCheckbox()
    this.refreshDisabled()
  }

  checkedCount() {
    return this.childCheckboxTargets.filter(target => target.checked === true).length
  }

  boxCount() {
    return this.childCheckboxTargets.length
  }

  refreshDisabled() {
    this.disableOnNoneTargets.forEach(target => target.disabled = (this.checkedCount() === 0))
  }

  refreshMasterCheckbox() {
    this.masterCheckboxTarget.indeterminate = (0 < this.checkedCount() && this.checkedCount() < this.boxCount())
    this.masterCheckboxTarget.checked = (this.checkedCount() === this.boxCount())
  }
}
