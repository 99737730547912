import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    paymentsUrl: String,
    formUrl: String
  }

  connect() {

  }

  loadPayments() {
    fetch(this.paymentsUrlValue, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    }).then((response) => response.text())
      .then((data) => {
        document.getElementById("paymentsOffcanvas").innerHTML = data
      })
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  loadForm() {
    fetch(this.formUrlValue, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    }).then((response) => response.text())
      .then((data) => {
        document.getElementById("new_payment_button").hidden = true
        document.getElementById("fully_paid_form").hidden = true
        document.getElementById("payments_form").innerHTML = data
        datepickerInit()
      })
  }

  hideForm() {
    document.getElementById("payments_form").innerHTML = ""
    document.getElementById("new_payment_button").hidden = false
    document.getElementById("fully_paid_form").hidden = document.querySelectorAll("tr[id^='payment_']").length
    Array.from(document.querySelectorAll("#payments_body tr")).map(tr => { tr.classList.remove("bg-secondary") })
  }

  fillRemainingAmount(e) {
    const remainingAmount = Number(document.getElementById("payments_body").dataset.remainingAmount)
    if ( remainingAmount > 0 ) {
      e.target.previousElementSibling.value = document.getElementById("payments_body").dataset.remainingAmount
    } else {
      notification('info', 'Remaining amount is 0')
    }
  }

  loadEditForm(e) {
    const editUrl = e.target.dataset.editUrl
    Array.from(document.querySelectorAll("#payments_body tr")).map(tr => { tr.classList.remove("bg-secondary") })
    e.target.closest("tr").classList.add("bg-secondary")

    fetch(editUrl, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    }).then((response) => response.text())
      .then((data) => {
        document.getElementById("new_payment_button").hidden = true
        document.getElementById("payments_form").innerHTML = data
        datepickerInit()
      })
  }

}
