import $ from "jquery";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import "./controllers"


window.$ = window.jQuery = $
// window.Parallax = require("parallax-js");
window.Popper = require("@popperjs/core")
window.SmoothScroll = require('smooth-scroll')
window.bootstrap = require('bootstrap');

require("./saas-v1/theme.js");
