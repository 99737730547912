import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dates", "from", "to" ]

  periodChange(event) {
    const select = event.target
    this.datesTarget.hidden = true
    if ( select.value == "custom_period" ) {
      this.fromTarget.value = ""
      this.toTarget.value = ""
      this.datesTarget.hidden = false
    } else {
      const from = select.options[select.selectedIndex].dataset.from
      const to = select.options[select.selectedIndex].dataset.to
      this.fromTarget.value = from
      this.toTarget.value = to
      select.closest("form").requestSubmit()
    }
  }
}