import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "input", "label", "inlineForm" ]

  setLabel() {
    const label = this.labelTarget
    if ( this.inputTarget.checked ) {
      label.innerText = label.dataset.checkedText
    } else {
      label.innerText = label.dataset.uncheckedText
    }

    if ( this.hasInlineFormTarget ) {
      Rails.fire(this.inlineFormTarget, "submit")
    }
  }
}
