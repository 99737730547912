import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "switch"]

  connect() {
    if (this.hasSwitchTarget) this.handleToggle()
  }

  handleToggle() {
    const hideOnToggle = document.querySelectorAll(".js-hide-on-toggle")
    const showOnToggle = document.querySelectorAll(".js-show-on-toggle")
    if (this.switchTarget.checked) {
      this.hideElements(hideOnToggle)
      this.showElements(showOnToggle)
      this.toggleLabelClasses()
    } else {
      this.hideElements(showOnToggle)
      this.showElements(hideOnToggle)
      this.toggleLabelClasses()
    }
  }

  hideElements(elements) {
    elements.forEach(elem => {
      elem.classList.add("d-none")
    })
  }

  showElements(elements) {
    elements.forEach(elem => {
      elem.classList.remove("d-none")
    })
  }

  toggleLabelClasses() {
    const checkedLabel = document.querySelector(".js-checked-label")
    const uncheckedLabel = document.querySelector(".js-unchecked-label")
    if (!checkedLabel || !uncheckedLabel) return

    if (this.switchTarget.checked) {
      checkedLabel.classList.remove("text-muted")
      uncheckedLabel.classList.add("text-muted")
    } else {
      checkedLabel.classList.add("text-muted")
      uncheckedLabel.classList.remove("text-muted")
    }
  }

  toggleSearchClearBtn(e) {
    if (e.target.value.length < 1) {
      this.switchTarget.classList.add('d-none')
      this.switchTarget.classList.remove('d-inline-block')
    } else {
      this.switchTarget.classList.remove('d-none')
      this.switchTarget.classList.add('d-inline-block')
    }
  }

  toggle_they_love_wrapp(e) {
    let ul = document.querySelectorAll('#they-love-wrapp-mobile li[data-hidden=true]')
    let btn = e.target
    ul.forEach((el) => {
      el.toggleAttribute('hidden')
    })
    if (btn.dataset.visibleElements === 'true') {
      btn.dataset.visibleElements = 'false'
      btn.textContent = btn.dataset.hideLabel
    } else {
      btn.dataset.visibleElements = 'true'
      btn.textContent = btn.dataset.showAllLabel
    }
  }

}
