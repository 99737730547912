import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"

export default class extends Controller {
  static targets = [ 
    "datePicker", 
    "greaterDateNotice", 
    "lowerDateNotice"
  ]

  connect() {
    this.datePickerInit()
  }

  constructDate(date) {
    const d = new Date(date)
    const todayString = [d.getFullYear(), (d.getMonth()+1), d.getDate()].join("-")
    return new Date(todayString)
  }

  issuedDateChange(event) {
    const elem = event.target
    const selectedDate = this.constructDate(elem.datepicker.getDate("yyyy-mm-dd"))
    const today = this.constructDate(new Date())
    this.lowerDateNoticeTarget.hidden = true
    this.greaterDateNoticeTarget.hidden = true

    if ( selectedDate < today ) {
      this.lowerDateNoticeTarget.hidden = false
    } else if ( selectedDate > today ) {
      this.greaterDateNoticeTarget.hidden = false
    }
  }

  datePickerInit() {
    this.datePickerTargets.forEach((elem) => {
      let dateFormat = "yyyy-mm-dd"
      let minDate, maxDate = null
      if ( elem.dataset.dateFormat ) { dateFormat = elem.dataset.dateFormat }
      if ( elem.dataset.minDate ) { minDate = elem.dataset.minDate }
      if ( elem.dataset.maxDate ) { maxDate = elem.dataset.maxDate }

      const datepicker = new Datepicker(elem, {
        showOnFocus: true,
        autohide: true,
        format: dateFormat,
        minDate: minDate,
        maxDate: maxDate
      })
    })
  }

  addAndSelectZeroVat(vatRate) {
    let newOption = document.createElement("option")
    newOption.value = "0"
    newOption.innerHTML = "0"
    vatRate.appendChild(newOption)
  }

  addZeroVatExemption(vatExemptionCode) {
    let newOption = document.createElement("option")
    newOption.value = "4"
    newOption.innerHTML = vatExemptionCode.dataset.zeroVatExemptionTitle
    vatExemptionCode.appendChild(newOption)
  }

}
