import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["supplier"]
  static values = {
    url: String, 
    projectSupplier: String
  }

  connect() {
    if ( this.projectSupplierValue ) {
      this.supplierChanged()
    }
  }

  supplierChanged() {
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      dataType: "script",
      data: `supplier_id=${this.supplierTarget.value}&project_supplier_id=${this.projectSupplierValue}`
    })
  }
}
