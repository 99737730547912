import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.listenForDaySelect()
  }

  allDays() {
    return [...document.querySelectorAll(".day")]
  }

  listenForDaySelect(event) {
    this.allDays().map(elem => {
      elem.addEventListener("click", () => {
        if ( !elem.classList.contains("selected") ) { this.setSelected(elem) }
      })
    })
  }

  setSelected(element) {
    this.allDays().map(day => { day.classList.remove("selected", "bg-warning") })
    element.classList.add("selected", "bg-warning")
    this.loadInvoices(element.querySelector(".this-date").dataset.date)
  }

  loadInvoices(date) {
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      dataType: "script",
      data: `date=${date}`
    })
  }
}
