import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus"
import {
    getMetaValue,
    toArray,
    findElement,
    removeElement,
    insertAfter
} from "../helpers";

export default class extends Controller {
    static targets = ["input"];
    static values = {
        url: String
    }
    connect() {
        this.dropZone = createDropZone(this);
        this.autoremoveFiles();
        Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    }

    autoremoveFiles() {
        const dz = this.dropZone
        this.dropZone.on('addedfile', function (file) {

            file.previewElement.querySelector(".dropzone-start").onclick = () => { this.valueOf('dropzone').enqueueFile(file); };
            const dropzoneItems = this.element.querySelectorAll('.dropzone-item');
            dropzoneItems.forEach(dropzoneItem => {
                dropzoneItem.style.display = '';
            });
            this.element.querySelector('.dropzone-upload').style.display = "inline-block";
            this.element.querySelector('.dropzone-remove-all').style.display = "inline-block";

        });
    }



    get headers() {
        return { "X-CSRF-Token": getMetaValue("csrf-token") };
    }

    get targetElement() {
        return this.data.get("target-element");
    }

    get url() {
        return this.inputTarget.getAttribute("data-direct-upload-url");
    }

    get maxFiles() {
        return this.data.get("maxFiles") || 1;
    }

    get maxFileSize() {
        return this.data.get("maxFileSize") || 256;
    }

    get acceptedFiles() {
        return this.data.get("acceptedFiles") || ["xlxs"];
    }

    get addRemoveLinks() {
        return this.data.get("addRemoveLinks") || false;
    }
}


function createDropZone(controller) {
    const el = document.getElementById(controller.targetElement);
    const options = {
        url: controller.urlValue,
        headers: controller.headers,
        maxFiles: controller.maxFiles,
        parallelUploads: 1,
        maxFilesize: controller.maxFileSize,
        addRemoveLinks: controller.addRemoveLinks,
        thumbnailMethod: "contain",
        autoQueue: false,
        previewsContainer: "drop-zone.dropzone-items",
        clickable: document.getElementsByClassName('manual-dropzone-trigger')[0]

    }
    var previewTemplate = document.getElementById('preview-template')
    if (previewTemplate) {
        options.previewTemplate = previewTemplate.innerHTML
    } else {
        var previewNode = el.querySelector(".dropzone-item");
        previewTemplate = previewNode.parentNode.innerHTML
        options.previewTemplate = previewTemplate
        previewNode.parentNode.removeChild(previewNode)
        options.previewsContainer = ".dropzone-items"
    }
    const dropzone = new Dropzone(el, options);
    dropzone.on("error", (file, message) => {
        var div = file.previewElement.querySelector('.dropzone-error')
        div.innerHTML = message.attachmentPartial;
    })

    dropzone.on("uploadprogress", function (file, progress, bytesSent) {
        const progressBar = file.previewElement.querySelectorAll('.progress-bar')[0];
        progressBar.style.width = progress + "%";

    });

    dropzone.on("sending", function (file) {
        // Show the total progress bar when upload starts
        const progressBars = el.querySelectorAll('.progress-bar');
        progressBars.forEach(progressBar => {
            progressBar.style.opacity = "1";
        });
        // And disable the start button
        file.previewElement.querySelector(".dropzone-start").setAttribute("disabled", "disabled");
    });
    dropzone.on("complete", function (progress) {
        const progressBars = el.querySelectorAll('.dz-complete');

        setTimeout(function () {
            progressBars.forEach(progressBar => {
                progressBar.querySelector('.progress-bar').style.opacity = "0";
                progressBar.querySelector('.progress').style.opacity = "0";
                progressBar.querySelector('.dropzone-start').style.opacity = "0";
            });
        }, 300);
    });
    el.querySelector(".dropzone-upload").addEventListener('click', function () {
        dropzone.enqueueFiles(dropzone.getFilesWithStatus(Dropzone.ADDED));
    });
    el.querySelector(".dropzone-remove-all").addEventListener('click', function () {
        el.querySelector('.dropzone-upload').style.display = "none";
        el.querySelector('.dropzone-remove-all').style.display = "none";
        dropzone.removeAllFiles(true);
    });
    dropzone.on("queuecomplete", function (progress) {
        const uploadIcons = el.querySelectorAll('.dropzone-upload');
        uploadIcons.forEach(uploadIcon => {
            uploadIcon.style.display = "none";
        });
    });
    dropzone.on("removedfile", function (file) {
        if (dropzone.files.length < 1) {
            el.querySelector('.dropzone-upload').style.display = "none";
            el.querySelector('.dropzone-remove-all').style.display = "none";
        }
    });

    dropzone.on("queuecomplete", () => {
        var statuses = dropzone.files.map((f) => { return f.status })
        statuses = [...new Set(statuses)]

        if (statuses.length == 1 && statuses[0] == 'success') {
            location.reload()

        }
    })

    return dropzone;
}
