import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "amount", "percentage", "trigger"]
  static values = {
    discountType: String,
    amount: Number
  }

  connect() {
    this.handleDiscountInputs()
    this.updateHiddenInput()
    this.setTriggerText()
  }

  handleDiscountInputs() {
    this.amountTarget.hidden = true
    this.percentageTarget.hidden = true
    this.discountTypeValue = this.element.querySelector(".discount-radios input[type=radio]:checked").value

    if ( this.discountTypeValue == "amount" ) {
      this.amountValue = Number(this.amountTarget.querySelector("input").value)
      this.amountTarget.hidden = false
    } else if ( this.discountTypeValue == "percentage" ) {
      this.amountValue = Number(this.percentageTarget.querySelector("input").value)
      this.percentageTarget.hidden = false
    }
  }

  toggleDiscountDropdown() {
    this.dropdownTarget.hidden = !this.dropdownTarget.hidden
  }

  closeOnOutsideClick(e) {
    if ( !this.element.contains(e.target) ) { this.dropdownTarget.hidden = true }
  }

  setTriggerText() {
    let defaultText = this.triggerTarget.dataset.defaultText

    if ( this.discountTypeValue == "amount" && this.amountValue > 0 ) {
      defaultText = "- " + this.amountValue + "€"
      defaultText += this.resetButton()
    } else if ( this.discountTypeValue == "percentage" && this.amountValue > 0 ) {
      defaultText = "- " + this.amountValue + "%"
      defaultText += this.resetButton()
    }

    this.triggerTarget.innerHTML = defaultText
  }

  resetButton() {
    return `<i class="ms-2 text-danger fa-regular fa-circle-xmark" data-action="click->discount-widget#reset"></i>`
  }

  submit() {
    this.setTriggerText()
    this.updateLine()
    this.updateHiddenInput()
    this.dropdownTarget.hidden = true
  }

  reset() {
    this.amountTarget.querySelector("input").value = 0
    this.amountValue = 0
    this.percentageTarget.querySelector("input").value = 0
    this.percentageValue = 0
    this.submit()
  }

  updateLine() {
    const line = this.element.closest(".js-line")

    line.querySelector(".js-discount-type").value = ""
    line.querySelector(".js-discount-amount").value = 0
    line.querySelector(".js-discount-percentage").value = 0

    if ( this.discountTypeValue == "amount" && this.amountValue > 0 ) {
      line.querySelector(".js-discount-type").value = this.discountTypeValue
      line.querySelector(".js-discount-amount").value = this.amountValue
    } else if ( this.discountTypeValue == "percentage" && this.amountValue > 0 ) {
      line.querySelector(".js-discount-type").value = this.discountTypeValue
      line.querySelector(".js-discount-percentage").value = this.amountValue
    }

    line.querySelector(".js-line-unit-price").dispatchEvent( new Event("input") )
  }

  updateHiddenInput() {
    let unitPrice = 0
    unitPrice = Number(this.element.closest(".js-line").querySelector(".js-line-unit-price").value)

    if ( this.discountTypeValue == "amount" && this.amountValue > 0 ) {
      const percentage = ((this.amountValue / unitPrice) * 100).toFixed(2)
      this.percentageTarget.querySelector("input").value = percentage
    } else if ( this.discountTypeValue == "percentage" && this.amountValue > 0 ) {
      const amount = (unitPrice * (this.amountValue / 100)).toFixed(2)
      this.amountTarget.querySelector("input").value = amount
    }
  }

}
