import { Controller } from "@hotwired/stimulus"
import accounting from "accounting"

export default class extends Controller {
  static targets = [ "account", "outstandingInvoices", "amount", "invoicesTotal", "surplus",
    "amountTotal", "invoice", "massSelect", "submit" ]

  static values = {
    "outstandingInvoicesUrl": String,
    "surplus": Number,
    "standalonePaymentId": String
  }

  connect() {
    this.loadOutstandingInvoices()
  }

  loadOutstandingInvoices() {
    const url = this.outstandingInvoicesUrlValue + `/?account_id=${this.accountTarget.value}` +
      `&standalone_payment_id=${this.standalonePaymentIdValue}`

    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        this.outstandingInvoicesTarget.innerHTML = data
        this.totalCalculations()
      })
  }

  handleInvoiceCheck(event) {
    const value = parseFloat(event.target.closest("tr").querySelector("input[type='number']").value || 0)
    const remainingAmount = parseFloat(event.target.closest("tr").dataset.remainingAmount)
    const maxValue = [remainingAmount, this.surplusValue].sort((a,b) => { return a-b })[0]

    if ( event.target.checked ) {
      event.target.closest("tr").querySelector("input[type='number']").value = remainingAmount
    } else {
      event.target.closest("tr").querySelector("input[type='number']").value = 0.00
    }

    this.totalCalculations()
  }

  totalCalculations() {
    let amountTotal = parseFloat(this.amountTarget.value) || 0
    let invoicesTotal = 0
    const info = this.amountTarget.nextElementSibling

    this.amountTarget.classList.remove("border", "border-danger")
    info.hidden = true

    this.invoiceTargets.map(invoice => {
      const value = parseFloat(invoice.querySelector("input[type='number']").value)
      const remainingAmount = parseFloat(invoice.dataset.remainingAmount)
      const checkbox = invoice.querySelector("input[type='checkbox']")
      invoicesTotal += value
      checkbox.checked = value == remainingAmount
    })

    if ( invoicesTotal > 0 ) { invoicesTotal = invoicesTotal.toFixed(2) }

    if ( amountTotal < invoicesTotal ) {
      info.innerText = info.dataset.text + ` ${invoicesTotal}`
      info.hidden = false
      this.submitTarget.disabled = true
      this.amountTarget.classList.add("border", "border-danger")
    } else if (amountTotal == 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
    this.surplusValue = amountTotal - invoicesTotal

    this.invoicesTotalTarget.closest('tr').hidden = !invoicesTotal
    this.surplusTarget.closest('tr').hidden = !invoicesTotal || !this.surplusValue

    this.invoicesTotalTarget.innerText = this.displayEuro(invoicesTotal)
    this.surplusTarget.innerText = this.displayEuro(this.surplusValue)
    this.amountTotalTarget.innerText = this.displayEuro(amountTotal)
  }

  displayEuro(amount) {
    return accounting.formatMoney(amount, "€", 2, ".", ",")
  }

  handleInvoiceAmountInput(event) {
    const value = parseFloat(event.target.value) || 0
    const remainingAmount = parseFloat(event.target.closest("tr").dataset.remainingAmount)

    if ( value > remainingAmount ) {
      event.target.value = remainingAmount.toFixed(2)
    }

    this.totalCalculations()
  }

  massSelect() {
    if ( this.massSelectTarget.checked ) {
      let amountTotal = parseFloat(this.amountTarget.value)
      if ( amountTotal > 0 ) {
        this.invoiceTargets.map(invoice => {
          const remainingAmount = parseFloat(invoice.dataset.remainingAmount)
          const value = (amountTotal - remainingAmount) >= 0 ? remainingAmount : amountTotal
          invoice.querySelector("input[type='number']").value = value
          amountTotal = (amountTotal - value).toFixed(2)
        })
      } else {
        this.invoiceTargets.map(invoice => {
          invoice.querySelector("input[type='number']").value = parseFloat(invoice.dataset.remainingAmount)
        })
      }
    } else {
      this.invoiceTargets.map(invoice => {
        invoice.querySelector("input[type='number']").value = 0.00
      })
    }
    this.totalCalculations()
  }

}
