import { Controller } from "@hotwired/stimulus"
import toastr from "toastr"

export default class extends Controller {
  static targets = [];

  connect() {
    let flash_key = this.data.get("key")
    let flash_value = this.data.get("value")

    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    switch (flash_key) {
      case "notice":
      case "success":
        toastr.success(flash_value);
        break;
      case "info":
        toastr.info(flash_value);
        break;
      case "warning":
        toastr.warning(flash_value);
        break;
      case "alert":
      case "error":
        toastr.error(flash_value);
        break;
      default:
        toastr.success(flash_value);
    }
  }
}
