import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = { reorderUrl: String }

  connect() {
    this.initSortable()
  }

  initSortable() {
    const sortable = new Sortable(this.element, {
      animation: 150,
      handle: ".sort-handler",
      onEnd: (event) => { 
        Rails.ajax({
          url: this.reorderUrlValue,
          type: "PUT",
          dataType: "script",
          data: "ids=" + sortable.toArray()
        })
      }
    })
  }
}
