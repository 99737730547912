import { Controller } from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  checkForNewAccount(e) {
    if ( e.target.value == "newAccount" ) {
      Turbolinks.visit(this.element.dataset.newAccountUrl)
    }
  }

}
