import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  validateTitle(e) {
    if ( document.getElementById("project_expense_project_expense_type_id").value == "" ) {
      alert("Please, select an expense Title")
      e.preventDefault()
    }
  }
}