import { Controller } from "@hotwired/stimulus"
import { CountUp } from "countup.js"

export default class extends Controller {
  connect() {
    const prefix = this.element.dataset.prefix ? this.element.dataset.prefix : ""
    const suffix = this.element.dataset.suffix ? this.element.dataset.suffix : ""
    const decimals = this.element.dataset.decimals ? this.element.dataset.decimals : ""

    new CountUp(this.element, this.element.dataset.number, {
      separator: ".",
      decimal: ",",
      prefix: prefix,
      suffix: suffix,
      decimalPlaces: decimals
    }).start()
  }

}