import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    generating: String
  }

  connect() {
    if ( this.generatingValue ) {
      this.interval = setInterval(() => { this.getPdfUrl(this.generatingValue) }, 3000)
    }
  }

  generatePdf(event) {
    const link = event.target.dataset.link
    fetch(link)
      .then(response => response.json())
      .then((data) => {
        if ( data.attached ) {
          this.downloadFile(data.url)
        } else {
          this.generatingValue = link
          this.interval = setInterval(() => { this.getPdfUrl(link) }, 3000)
        }
      })
  }

  getPdfUrl(link) {
    fetch(link)
      .then(response => response.json())
      .then((data) => {
        if ( data.attached ) {
          this.generatingValue = ""
          clearInterval(this.interval)
          this.downloadFile(data.url)
        }
      })
  }

  setButtonState() {
    if ( this.generatingValue ) {
      if ( this.buttonTarget.dataset.dropdown == "true" ) {
        this.buttonTarget.classList.remove("dropdown-toggle")
      }
      this.buttonTarget.dataset.ktIndicator = "on"
      this.buttonTarget.dataset.ktMenuTrigger = ""
    } else {
      if ( this.buttonTarget.dataset.dropdown == "true" ) {
        this.buttonTarget.classList.add("dropdown-toggle")
      }
      this.buttonTarget.dataset.ktIndicator = ""
      this.buttonTarget.dataset.ktMenuTrigger = "hover"
    }
  }

  generatingValueChanged() {
    this.setButtonState()
  }

  downloadFile(url) {
    let downloadElement = document.createElement("a")
    downloadElement.href = url
    downloadElement.setAttribute("download", "")
    this.element.appendChild(downloadElement)
    downloadElement.click()
  }
}
