import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  handleCheck(e) {
    e.target.form.submit()
  }


}

