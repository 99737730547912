import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import SlimSelect from "slim-select"

export default class extends Controller {

  connect() {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map(elem => {
      bootstrap.Tooltip.getOrCreateInstance(elem)
    })
    document.querySelectorAll("select.select2:not([data-ssid])").forEach(el => {
      new SlimSelect({ select: el })
    })
  }

  hideTooltips() {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map(elem => {
      bootstrap.Tooltip.getOrCreateInstance(elem).hide()
    })
  }

  fillModal(event) {
    const modal = new bootstrap.Modal(document.getElementById("supplier-role-note-modal"))
    modal.show()
    const url = event.target.dataset.url
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "script"
    })
  }

}
