import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["durationInput", "basicCostDetails", "addonCheckboxes"]

  connect() {
    this.videoSort()
  }

  submitForm(event) {
    const form = event.target.closest('form')
    Rails.fire(form, "submit")
  }

  toggleBasicCost() {
    this.basicCostDetailsTargets.map(item => { item.hidden = !item.hidden })
  }

  toggleAddon(event) {
    const id = event.target.closest("span").id
    document.querySelectorAll(`.addon-${id}`).forEach(item => { item.hidden = !item.hidden })
  }

  handleAddonCheckboxes() {
    let selectedAddons = []
    let form = document.getElementById("generic-budget-templates-show-form")
    this.addonCheckboxesTargets.map(checkbox => {
      if ( checkbox.checked ) { selectedAddons.push(checkbox.value) }
    })
    console.log(selectedAddons)
    document.getElementById("selected_addons").value = selectedAddons
    Rails.fire(form, "submit")
  }

  videoSort() {
    const el = document.querySelector(".budget-template-videos")
    const sortUrl = el.dataset.url
    const videosSortable = Sortable.create(el, {
      handle: ".fa.fa-arrows",
      onEnd: () => {
        Rails.ajax({
          type: "POST",
          url: sortUrl,
          dataType: "script",
          data: "video_ids=" + videosSortable.toArray()
        })
      }
    })
  }
  
}