import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["vatExemption", "vatRate", "desktopFields", "modal", "mobileFields", "stampDutyAmountDiv",
    "stampDutyTaxCode", "deductionsModal"]

  initialize() {
    document.getElementById("nested-rows").addEventListener("cocoon:after-remove", () => {
      this.calculateLineNums()
    })
    this.invoiceType = document.getElementById("invoice_invoice_type")
  }

  connect() {
    this.calculateLineNums()
    this.handleVatExemption()
    this.handleCustomStampDuty()
  }

  disconnect() {
    this.calculateLineNums()
  }

  calculateLineNums() {
    let i = 0
    let types = ["name", "quantity", "subtotal"]

    document.querySelectorAll(".js-line").forEach((line) => {
      if (line.style.display === "none") return     // Reject nested fields set to destroy in edit form
      let line_number = (i + 1).toString()
      line.querySelector(".js-invoice-line-num").value = line_number

      types.forEach( (item) => {
        let element = line.querySelector(`.invoice-line-item-${item}`)
        element.id = `invoice_line_${item}_${line_number}`
        element.name = `invoice_line_${item}_${line_number}`
      })
      i++
    })
  }

  handleVatExemption() {
    this.vatExemptionTarget.hidden = this.vatRateTarget.value != "0"
  }

  setSelected(elem, value, text) {
    const options = Array.from(elem.querySelectorAll("option"))
      .map((option) => { return option.value })
      .filter((a) => { return a != "" })

    if ( !options.includes(value) ) {
      let newOption = document.createElement("option")
      newOption.value = value
      newOption.innerHTML = text
      elem.appendChild(newOption)
    }
    elem.value = value
    elem.dispatchEvent(new Event("input"))
  }

  moveToModal() {
    let modal = bootstrap.Modal.getInstance(this.modalTarget)
    if ( !modal ) {
      modal = new bootstrap.Modal(this.modalTarget)
      const _this = this
      this.modalTarget.addEventListener("hide.bs.modal", function (event) {
        if ( event.target.classList.contains("invoice-line--modal") ) {
          _this.updateName()
          _this.moveDesktopFieldsBack()
        }
      })
    }
    this.moveDesktopFieldsToModal()
    modal.show()
  }

  updateName() {
    this.element.querySelector(".invoice-line-item-name").value = this.element.querySelector(".js-line-name").value
  }

  moveDesktopFieldsBack() {
    this.desktopFieldsTarget.classList.add("d-none")
    this.mobileFieldsTarget.after(this.desktopFieldsTarget)
  }

  moveDesktopFieldsToModal() {
    this.desktopFieldsTarget.classList.remove("d-none")
    this.modalTarget.querySelector(".modal-body").append(this.desktopFieldsTarget)
  }

  mobileValidations() {
    const name = this.modalTarget.querySelector(".js-line-name")
    const nameValue = this.modalTarget.querySelector(".js-line-name").value
    const quantity = this.modalTarget.querySelector(".js-line-quantity")
    const quantityValue = quantity ? Number(this.modalTarget.querySelector(".js-line-quantity").value) : 1
    const unitPrice = this.modalTarget.querySelector(".js-line-unit-price")
    const unitPriceValue = Number(this.modalTarget.querySelector(".js-line-unit-price").value)
    const vatRate = this.modalTarget.querySelector(".js-line-vat-rate")
    const vatRateValue = this.modalTarget.querySelector(".js-line-vat-rate").value
    const vatExemption = this.vatExemptionTarget.querySelector("select")
    const vatExemptionValue = Number(this.vatExemptionTarget.querySelector("select").value)
    const fields = [name, quantity, unitPrice, vatRate, vatExemption]
    const modal = bootstrap.Modal.getInstance(this.modalTarget)
    let hasError = false

    fields.map(elem => {
      if (!elem) return false
      this.removeMobileError(elem)
    })

    if ( nameValue == "" ) {
      this.addMobileError(name)
      hasError = true
    }

    if (quantityValue < 1 && this.invoiceType.value !== 'sales_invoice_behalf_clearence') {
      this.addMobileError(quantity)
      hasError = true
    }

    if (!this.invoiceType.value === 'delivery_note') {


      if (unitPriceValue == 0) {
        this.addMobileError(unitPrice)
        hasError = true
      }

      if (vatRateValue == "") {
        this.addMobileError(vatRate)
        hasError = true
      }

      if (vatRateValue == 0 && vatExemptionValue == 0) {
        this.addMobileError(vatExemption)
        hasError = true
      }
    }

    if (!hasError) { modal.hide() }
  }

  addMobileError(element) {
    const errorMessage = this.element.dataset.mobileErrorMessage
    element.classList.add("border-danger")
    element.closest(".form-floating").insertAdjacentHTML("afterend",
      `<div class="mobile-error text-danger fs-7">*${errorMessage}</div`)
  }

  removeMobileError(element) {
    element.classList.remove("border-danger")
    element.closest(".form-floating")?.parentElement?.querySelector(".mobile-error")?.remove()
  }

  clearLineFields() {
    const name = this.modalTarget.querySelector(".js-line-name")
    const quantity = this.modalTarget.querySelector(".js-line-quantity")
    const unitPrice = this.modalTarget.querySelector(".js-line-unit-price")
    const vatRate = this.modalTarget.querySelector(".js-line-vat-rate")
    const vatExemption = this.vatExemptionTarget.querySelector("select")
    const fields = [name, quantity, unitPrice, vatRate, vatExemption]
    const modal = bootstrap.Modal.getInstance(this.modalTarget)

    this.modalTarget.querySelector(".js-line-name").value = ""
    if (this.invoiceType.value !== 'sales_invoice_behalf_clearence') {
      this.modalTarget.querySelector(".js-line-quantity").value = 1
    }
    this.modalTarget.querySelector(".js-line-unit-price").value = 0
    this.modalTarget.querySelector(".js-line-unit-price").dispatchEvent(new Event("input"))
    this.modalTarget.querySelector(".js-line-vat-rate").value = '24'
    this.vatExemptionTarget.querySelector("select").value = ""

    fields.map(elem => {
      if (!elem) return false
      this.removeMobileError(elem)
    })
    modal.hide()
  }

  handleCustomStampDuty() {
    if ( this.hasStampDutyTaxCodeTarget ) {
      this.stampDutyAmountDivTarget.hidden = this.stampDutyTaxCodeTarget.value != 4
    }
  }

  openDeductionsModal() {
    const modal = new bootstrap.Modal(this.deductionsModalTarget)
    modal.toggle()
  }

  removeDeduction(e) {
    e.target.previousElementSibling.value = "true"
    e.target.closest(".row").hidden = true
  }

}
