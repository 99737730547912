import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["vat", "net", "payable"]
  static values = { url: String }

  calculate() {
    this.payableTarget.value = (Number(this.vatTarget.value) + Number(this.netTarget.value)).toFixed(2)
  }

  connect() {
    document.querySelectorAll("select.select2:not([data-ssid])").forEach(el => {
      new SlimSelect({ select: el })
    })
    const selectSupplier = document.querySelector(".js-supplier-select")
    if (selectSupplier && selectSupplier.value.trim() !== "") {
      const vatNo = document.querySelector(".js-issuer-vat-no")
      vatNo.readOnly = true
    }
  }

  handleSupplierChange(e) {
    if (e.target.value != undefined && e.target.value.trim() !== "") {
      Rails.ajax({
        url: this.urlValue,
        type: "GET",
        data: "supplier_id=" + e.target.value
      })
    } else {
      this.clearSupplierFields()
    }
  }

  clearSupplierFields() {
    const vatNo = document.querySelector(".js-issuer-vat-no")
    const name = document.querySelector(".js-issuer-name")
    const address = document.querySelector(".js-issuer-address")
    const country = document.querySelector(".js-issuer-country")
    vatNo.value = ""
    name.value = ""
    address.value = ""
    country.value = country.dataset["defaultOption"]
    vatNo.readOnly = false
  }
}
