import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["magnifier", "accountId", "correlatedMarksInput"]

  connect() {
    this.refreshInputAndMagnifier()
  }

  handleAccountChanged() {
    this.correlatedMarksInputTarget.value = ""
    this.refreshInputAndMagnifier()
    // document.getElementById('other_taxes_percent_category').dispatchEvent(new Event("change"))
    let el = document.querySelector("select[id*='other_taxes_percent_category']")
    if (el) el.dispatchEvent(new Event("change"))
  }

  refreshInputAndMagnifier() {
    this.correlatedMarksInputTarget.disabled = !this.isAccountSelected()
    this.magnifierTarget.disabled = !this.isAccountSelected()
    this.modifyMagnifierTooltip()
  }

  modifyMagnifierTooltip() {
    this.magnifierTarget.dataset.bsOriginalTitle = this.isAccountSelected() ?
      this.magnifierTarget.dataset.enabledTooltipText : this.magnifierTarget.dataset.disabledTooltipText
  }

  isAccountSelected() {
    return (this.accountIdTarget && this.accountIdTarget.value !== "")
  }

  searchClicked(e) {
    if (!this.isAccountSelected()) return
    let invoice_type
    let url = e.target.dataset.url
    let for_invoice_type = e.target.dataset.invoice_type
    if (['credit_correlated', 'service_invoice_supplemental', 'accomondation_tax'].includes(for_invoice_type)) {
      invoice_type = 'invoice'
    } else if (for_invoice_type === 'sales_invoice_supplemental') {
      invoice_type = 'sales_invoice'
    } else {
      invoice_type = ''
    }
    Rails.ajax({
      url: url,
      type: "POST",
      data: `account_id=${this.accountIdTarget.value}&invoice_type=${invoice_type}&for_invoice_type=${for_invoice_type}`,
    })
    $("#correlatedInvoiceOptionsModal").modal("show")
  }
}
