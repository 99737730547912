import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "password", "confirmation", "length", "match", "submit" ]

  checkMatch() {
    return this.passwordTarget.value === this.confirmationTarget.value && this.passwordTarget.value.length > 0
  }

  checkLength() {
    return this.passwordTarget.value.length >= 6
  }

  validate() {
    this.lengthTarget.querySelector("i").classList.remove("text-success")
    this.matchTarget.querySelector("i").classList.remove("text-success")
    if ( this.checkLength() ) { this.lengthTarget.querySelector("i").classList.add("text-success") }
    if ( this.checkMatch() ) { this.matchTarget.querySelector("i").classList.add("text-success") }
    this.submitTarget.disabled = !(this.checkLength() && this.checkMatch())
  }

}
