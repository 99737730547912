import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]
  static values = {videoType: Array, applicationType: Array}

  connect() {
    const vtElem = document.getElementById("js-filter-video-type")
    this.videoTypeValue = Array.from(JSON.parse(vtElem.dataset.value)).filter(Boolean)
    const atElem = document.getElementById("js-filter-application-type")
    this.applicationTypeValue = Array.from(JSON.parse(atElem.dataset.value)).filter(Boolean)
    if (this.hasVideoFilters() || this.hasApplicationFilters()) {
      this.filterShownTemplates()
      this.initFiltersActive()
    }
  }

  initFiltersActive() {
    this.videoTypeValue.forEach(val => {
      let elem = document.querySelector(`.js-video-filter-link[data-value='${val}']`)
      if (elem) elem.classList.add("filter-active")
    })
    this.applicationTypeValue.forEach(val => {
      let elem = document.querySelector(`.js-application-filter-link[data-value='${val}']`)
      if (elem) elem.classList.add("filter-active")
    })
  }

  setVideoType(e) {
    e.stopPropagation()
    e.preventDefault()
    e.target.classList.toggle("filter-active")
    const videoType = e.target.dataset.value
    if (this.videoTypeValue.includes(videoType)) {
      this.videoTypeValue = this.videoTypeValue.filter(val => val !== videoType).filter(Boolean)
    } else {
      this.videoTypeValue = this.videoTypeValue.concat([videoType]).filter(Boolean)
    }
    this.filterShownTemplates()
  }

  buildParams() {
    const baseUrl = new URL(document.getElementById("js-base-url").dataset.url)
    let params = new URLSearchParams()
    this.videoTypeValue.forEach(val => {
      params.append("video_type[]", val)
    })
    this.applicationTypeValue.forEach(val => {
      params.append("application_type[]", val)
    })
    history.pushState({}, null, baseUrl + "?" + params)
  }

  resetFilters(e) {
    e.preventDefault()
    this.videoTypeValue = []
    this.applicationTypeValue = []
    this.filterShownTemplates()
    this.clearAllToggles()
  }

  clearAllToggles() {
    document.querySelectorAll(".js-video-filter-link").forEach(elem => elem.classList.remove("filter-active"))
    document.querySelectorAll(".js-application-filter-link").forEach(elem => elem.classList.remove("filter-active"))
  }

  setApplicationType(e) {
    e.stopPropagation()
    e.preventDefault()
    e.target.classList.toggle("filter-active")
    const appType = e.target.dataset.value
    if (this.applicationTypeValue.includes(appType)) {
      this.applicationTypeValue = this.applicationTypeValue.filter(val => val !== appType).filter(Boolean)
    } else {
      this.applicationTypeValue = this.applicationTypeValue.concat([appType]).filter(Boolean)
    }
    this.filterShownTemplates()
  }

  filterShownTemplates() {
    this.templateTargets.forEach(target => {
        const currType = target.dataset.videoType
        const currApps = target.dataset.applicationTypes
        if ((!this.hasVideoFilters() && !this.hasApplicationFilters())
          || (this.hasVideoFilters() && this.videoTypeValue.some(elem => currType.includes(elem)))
          || (this.hasApplicationFilters() && this.applicationTypeValue.some(elem => currApps.includes(elem)))) {
          target.classList.remove("d-none")
        } else {
          target.classList.add("d-none")
        }
      }
    )
    this.buildParams()
    this.updateFilterPills()
  }

  updateFilterPills() {
    const typeContainer = document.querySelector(".js-type-filters-container")
    typeContainer.innerHTML = ""
    if (this.hasVideoFilters()) {
      this.videoTypeValue.forEach(vType => {
        let newElem = document.createElement("span")
        newElem.classList.add("filter-pill")
        newElem.textContent = vType
        typeContainer.appendChild(newElem)
      })
    }
    const appContainer = document.querySelector(".js-application-filters-container")
    appContainer.innerHTML = ""
    if (this.hasApplicationFilters()) {
      this.applicationTypeValue.forEach(aType => {
        let newElem = document.createElement("span")
        newElem.classList.add("filter-pill")
        newElem.textContent = aType
        appContainer.appendChild(newElem)
      })
    }
  }

  hasVideoFilters() {
    return !!this.videoTypeValue && this.videoTypeValue.length > 0
  }

  hasApplicationFilters() {
    return !!this.applicationTypeValue && this.applicationTypeValue.length > 0
  }
}
