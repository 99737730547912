import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.querySelector('.js-account-select').addEventListener('change', (evt) => this.handleAccountChange(evt));
  }

  refreshContactsContainer() {
    const contactContainer = document.querySelector('.js-account-contacts-container')
    contactContainer.innerHTML = ""
    const contacts = this.getContactsForAccount(this.getCurrAccountID())
    contacts.forEach( contact => {
      const contactDataArray = contact.filter(n => n)
      if (contactDataArray.length > 1) {
        contactContainer.appendChild(this.createContactElement(contactDataArray.join(" - ")))
      } else {
        contactContainer.appendChild(this.createContactElement(contactDataArray))
      }
    })
  }

  getContactsForAccount(accountID) {
    const accountData = document.querySelector(`.js-account-data[data-account-id='${accountID}']`);
    return JSON.parse(accountData.dataset.contactEmailsPhones);
  }

  createContactElement(contact) {
    const element = document.createElement("p");
    element.classList.add("form-control");
    element.textContent = contact;
    return element;
  }

  getCurrAccountID(){
    return document.querySelector('.js-account-select').value
  }

  handleAccountChange(evt) {
    this.refreshContactsContainer()
  }
}
