import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  enforceValueLimits(e) {
    const elem = e.target
    if (elem.value === "" || Number.isNaN(elem.value)) return

    if (parseInt(elem.value) > parseInt(elem.max)) elem.value = elem.max
    if (parseInt(elem.value) < parseInt(elem.min)) elem.value = elem.min
  }
}
