import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taxExemptions", "withholdTaxesSelect", "specialWithholdTaxCheckbox", "vat"]

  connect() {
    if (this.hasTaxExemptionsTarget) this.handleTaxExemptions()
    this.handleWithholdTaxChange()
    this.handleWithholdTaxOptions()
  }

  handleTaxExemptions() {
    this.taxExemptionsTarget.hidden = this.vatTarget.value !== '0'
  }

  handleWithholdTaxChange() {
    let el = document.getElementById('withhold-taxes-info')
    if (el) el.hidden = document.querySelector('[id*="invoice_preference_withhold_taxes"]').selectedOptions[0].value !== '10'
  }

  tooltipToggle(e) {
    e.target.closest('label').querySelector('.tooltip-text').classList.toggle('d-none')
  }

  handleWithholdTaxOptions() {
    if (this.hasSpecialWithholdTaxCheckboxTarget && this.hasWithholdTaxesSelectTarget) {
      const withholdTaxOptions = Array.from(this.withholdTaxesSelectTarget.options)

      if (this.specialWithholdTaxCheckboxTarget.checked) {
        withholdTaxOptions.find(o => { return o.value == "10" }).selected = false
        withholdTaxOptions.find(o => { return o.value == "10" }).disabled = true
      } else {
        withholdTaxOptions.find(o => { return o.value == "10" }).disabled = false
      }
    }
  }

}

