import { Controller } from "@hotwired/stimulus"
import * as classList from "@splidejs/splide/src/js/utils";

export default class extends Controller {

  static targets = ['supportPlanRadio']
  connect() {
    if ( this.supportPlanRadioTargets.length > 0 ) this.handleSupportPlanSwitch()
  }

  handleSupportPlanSwitch() {
    let support_premium_feature = document.querySelectorAll('.support-premium-feature')
    let support_plan_pill = document.querySelectorAll('.support-plan-pill')

    this.supportPlanRadioTargets.forEach( t => {
      if (t.id == 'support-basic-plan' && t.checked == true) {
        support_plan_pill.forEach( p => {
          p.innerText = "Support Basic"
          p.classList.remove('support-premium-plan-colors')
          p.classList.add('support-basic-plan-colors')
        })

        support_premium_feature.forEach( p => {
          p.classList.add('d-none')
        })

        this.updatePrices(t.id)
      } else if (t.id == 'support-premium-plan' && t.checked == true) {
        support_plan_pill.forEach( p => {
          p.innerText = "Support Premium"
          p.classList.add('support-premium-plan-colors')
          p.classList.remove('support-basic-plan-colors')
        })
        support_premium_feature.forEach( p => {
          p.classList.remove('d-none')
        })

        this.updatePrices(t.id)
      }
    })
  }

  updatePrices(plan) {
    let priceElements = document.querySelectorAll('.plan-price')
    let planPrice = plan == 'support-basic-plan' ? 'basicPrice' : 'premiumPrice'

    priceElements.forEach( p => {
      p.innerText = p.dataset[planPrice]
    })
  }
}
