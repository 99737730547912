import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['updateBtn']

  connect() {

    $('#personalAccountForm').on("hidden.bs.modal", () => {
      this.resetModal()
    })

  }

  validateElements() {
    let elements = document.getElementById('personalAccountForm').querySelectorAll('input[type="text"]')
    let errors = false
    elements.forEach( (element) => {
      if (element.value.length < 1) {
        element.classList.add('is-invalid')
        errors = true
      } else {
        element.classList.remove('is-invalid')
      }
    })

    this.updateBtnTarget.disabled = errors

    return !errors
  }

  updateAccount() {
    // Send data to update specific account
    // Get response, handle succesfull or error
    let formData = new FormData()
    let elements = document.querySelectorAll('#personalAccountForm input[type="text"]:not([disabled])')
    elements.forEach( (element) => {
      formData.append(`account[${element.name}]`, element.value)
    })
    formData.append("account[role]", 'personal')
    Rails.ajax({
      url: document.getElementById('personalAccountForm').dataset.updatePath,
      type: "POST",
      data: formData,
      dataType: "json",
      success: (response) => {
        if (response['account_updated'] === 'true') {
          let accountFormModal = bootstrap.Modal.getOrCreateInstance(document.querySelector("#personalAccountForm"))
          accountFormModal.hide()

          let account_select = document.getElementById('invoice_account_id')
          account_select.selectedOptions[0].removeAttribute('data-show-modal-url')
          account_select.selectedOptions[0].dataset.vatAddressRequired = 'false'
          account_select.dispatchEvent(new Event('change'))
        } 
      }
    })
  }

  resetModal() {
    let elements = document.getElementById('personalAccountForm').querySelectorAll('input[type="text"]')
    elements.forEach( (element) => {
      element.value = ''
      element.classList.remove('is-invalid')
      this.updateBtnTarget.disabled = true
    })
    document.getElementById('personalAccountForm').removeAttribute('data-update-path')
    document.getElementById('personalAccountForm').removeAttribute('data-account-id')
  }
}
