import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    
  }

  handleDuration(e) {
    const minutes = e.target.querySelector("option:checked").dataset.minutes
    e.target.nextElementSibling.value = minutes
  }

  handleRateType(e) {
    const rateType = e.target.querySelector("option:checked").dataset.rateType
    e.target.nextElementSibling.value = rateType
  }
}