import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "provider", "endTime", "endTimeWrapper" ]

  connect() {
    this.handleEndTime()
  }

  handleEndTime() {
    if ( this.providerTarget.value == 'youtube' ) {
      this.endTimeWrapperTarget.hidden = false
    } else {
      this.endTimeTarget.value = ""
      this.endTimeWrapperTarget.hidden = true
    }
  }
}