import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  connect() {
    
  }

  filter(e) {
    const type = e.target.dataset.type
    const value = e.target.value.toLowerCase()
    const divs = document.querySelectorAll(`div[data-div-type="${type}"]`)

    divs.forEach(div => {
      div.hidden = true
      if ( div.dataset.val.toLowerCase().includes(value) ) {
        div.hidden = false
      }
    })
  }

  replaceVideo(e) {
    const videoId = e.target.dataset.videoId
    const container = e.target.parentNode
    const height = container.clientHeight
    container.innerHTML = `<iframe src="https://www.youtube.com/embed/${videoId}?rel=0" width="100%" height="${height}" 
      style="border: 0;" allowfullscreen></iframe>`
  }
}