import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  openDialogBox(e) {
    e.preventDefault()
    if (typeof Cookiebot != "undefined") {
      Cookiebot.show()
    } else {
      console.log('cookiebot is not loaded')
    }
  }
}
