import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = ['contractForm', 'contractTable'];

	static values = { formShown: Boolean };

	connect() {
		this.formShownValue = false;
	}

  formShownValueChanged() {
    if(!this.hasContractFormTarget || !this.hasContractTableTarget){return;}

    if (this.formShownValue) {
      this.contractFormTarget.classList.replace('d-none', 'd-flex');
      this.contractTableTarget.classList.replace('table', 'd-none');
    } else {
      this.contractFormTarget.classList.replace('d-flex', 'd-none');
      this.contractTableTarget.classList.replace('d-none', 'table');
    }
  }

	toggleContractForm() {
		this.formShownValue = !this.formShownValue;
	}

  limitInvoices() {
    const element = event.target
    const existingInvoices = document.querySelectorAll(".uploaded-invoice").length
    if ( (element.files.length + existingInvoices) > 2 ) {
      element.value = null
      alert("Please upload up to 2 invoices")
    }
  }

  submitForm() {
    const form = event.target.closest("form")
    Rails.fire(form, "submit")
  }

  showPaymentStatusSelect() {
    event.target.hidden = true
    event.target.nextElementSibling.hidden = false
  }

  closePaymentStatusSelect() {
    event.target.parentNode.hidden = true
    event.target.parentNode.previousElementSibling.hidden = false
  }

  handleOutsideClick(e) {
    if ( !e.target.closest("[id^=payment_status]") ) {
      [...document.querySelectorAll("[id^=payment_status]")].map(el => {
        if ( el.querySelector("form").hidden  == false ) {
          el.querySelector("form").hidden = true
          el.querySelector("span").hidden = false
        }
      })
    }
  }
}
