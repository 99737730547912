import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  connect() {
    this.initSelect()
  }

  editMode(e) {
    const target = e.target.classList.contains("hover-outline") ? e.target : e.target.parentElement
    target.hidden = true
    const editable = target.nextElementSibling
    editable.hidden = false
    if ( target.dataset.loadUrl && !editable.classList.contains("loaded") ) {
      fetch(target.dataset.loadUrl, {
        method: "GET",
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          "Accept": "text/html"
        }
      }).then((response) => response.text())
        .then((data) => {
          editable.innerHTML = data
          editable.classList.add("loaded")
          this.initSelect()
        })
    } else {
      let focusElement = editable.querySelector(".form-control")
      if ( focusElement ) focusElement.focus()
    }
  }

  blur(e) {
    if ( e.target.value != e.target.dataset.value ) {
      e.target.closest("form").requestSubmit()
    } else {
      e.target.closest(".editable").hidden = true
      e.target.closest(".editable").previousElementSibling.hidden = false
    }
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  initSelect() {
    [...this.element.querySelectorAll(".elem-select2:not(.ss-main)")].map(el => {
      if ( el.slim == null ) new SlimSelect({ select: el })
    })
  }
}
