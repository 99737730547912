// import masonryGrid from './components/masonry-grid';
import stickyNavbar from './components/sticky-navbar';
// import navbarSearch from './components/navbar-search';
import passwordVisibilityToggle from './components/password-visibility-toggle';
// import fileDropArea from './components/file-drop-area';
import formValidation from './components/form-validation';
// import inputFormatter from './components/input-formatter';
import smoothScroll from './components/smooth-scroll';
import scrollTopButton from './components/scroll-top-button';
import tooltip from './components/tooltip';
import popover from './components/popover';
import toast from './components/toast';
// import gallery from './components/gallery';
import subscriptionForm from './components/subscription-form';
import labelUpdate from './components/form-label-update';
// import carousel from './components/carousel';
// import charts from './components/charts';
import countdown from './components/countdown';
// import datePicker from './components/date-picker';
// import radioTab from './components/radio-tab';
// import parallax from './components/parallax';
// import priceSwitch from './components/price-switch';
import productGallery from './components/product-gallery';
// import videoBtn from './components/btn-video';
// import rangeSlider from './components/range-slider';
import viewSwitcher from './components/view-switcher';
import checkboxToggleClass from './components/checkbox-toggle-class';
import masterCheckbox from './components/master-checkbox';
