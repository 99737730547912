import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {

  connect() {
  }

  updateSettings(e) {
    Rails.fire(e.target.closest("form"), "submit")
  }
}
