import {Controller} from "@hotwired/stimulus"
import Tribute from 'tributejs';

export default class extends Controller {
  static targets = ['templateSection'];

  addSection(e) {
    const target = this.templateSectionTargets[this.templateSectionTargets.length - 1]
    const clone = target.cloneNode(true);
    const inputElements = Array.from(clone.querySelectorAll('input, textarea'));
    inputElements.forEach(el => {
      el.value = null;
    });

    const sectionActions = clone.querySelector('.section-actions');
    sectionActions.classList.remove('d-none');
    sectionActions.classList.add('d-flex');

    clone.classList.add('removable-section');
    target.parentNode.appendChild(clone);

    this.recomputeSectionIndices();
  }

  removeSection(e) {
    e.target.closest('.removable-section').remove();
    this.recomputeSectionIndices();
  }

  recomputeSectionIndices() {
    this.templateSectionTargets.forEach((el, idx) => {
      el.querySelector('.section-index').innerText = `${idx + 1}.`
    });
  }
}
