import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["moveOfPurpose", "customTitle"]

  connect() {
    this.moveOfPurposeCustomTitle()
  }

  moveOfPurposeCustomTitle() {
    if (this.hasMoveOfPurposeTarget && this.moveOfPurposeTarget.value == 19) {
      this.customTitleTarget.disabled = false
    } else if (this.hasCustomTitleTarget) {
      this.customTitleTarget.value = ""
      this.customTitleTarget.disabled = true
    }
  }
}
