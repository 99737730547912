import {Controller} from "@hotwired/stimulus"
import Tribute from 'tributejs';

export default class extends Controller {
  static targets = ['mentionSelector']
  static values = {
    mentionOptions: Array
  };

  initSectionAutocomplete() {
    const elements = this.mentionSelectorTargets;
    [...elements].forEach((element) => this.attachAutocomplete(element));
  }

  attachAutocomplete(element) {
    const tribute = new Tribute({
      values: this.mentionOptionsValue,
      selectTemplate: function (item) {
        return `{{${item.original.value}}}`;
      },
    });

    tribute.attach(element)
  }


  // Called on page initialization
  connect() {
    this.initSectionAutocomplete();
  }
}
