import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['pricingContainer']

  connect() {
  }

  subscriptionSwitch(e) {
    if (e.target.classList.contains('btn-primary')) return
    let monthly_btn = document.querySelector("[data-subscription='monthly']")
    let annual_btn = document.querySelector("[data-subscription='annual']")

    let selected = e.target.closest('div').querySelector('.btn-primary')
    let unselected = e.target.closest('div').querySelector('.btn-secondary')
    let labels_per_month = document.getElementsByClassName('per-month')
    let labels_per_year = document.getElementsByClassName('per-year')
    let labels_price = document.getElementsByClassName('price')

    selected.classList.remove('btn-primary')
    selected.classList.add('btn-secondary')
    unselected.classList.remove('btn-secondary')
    unselected.classList.add('btn-primary')

    if (monthly_btn.classList.contains('btn-primary')) {
      for (let label of labels_per_month) {
        label.classList.remove('d-none')
      }

      for (let label of labels_per_year) {
        label.classList.add('d-none')
      }

      for (let label of labels_price) {
        label.innerHTML = label.dataset.monthlyPrice
      }

    } else if (annual_btn.classList.contains('btn-primary')) {
      for (let label of labels_per_year) {
        label.classList.remove('d-none')
      }

      for (let label of labels_per_month) {
        label.classList.add('d-none')
      }

      for (let label of labels_price) {
        label.innerHTML = label.dataset.annualPrice
      }
    }
  }

}
