import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["profitForm", "profitTrigger", "taxForm", "taxTrigger", "clientPriceTrigger", "clientPriceForm"]

  showProfitForm() {
    this.profitTriggerTarget.classList.add("d-none")
    this.profitFormTarget.hidden = false
  }

  hideProfitForm() {
    this.profitTriggerTarget.classList.remove("d-none")
    this.profitFormTarget.hidden = true
  }

  showVatForm() {
    this.taxFormTarget.hidden = false
    this.taxTriggerTarget.classList.add("d-none")
  }

  hideVatForm() {
    this.taxFormTarget.hidden = true
    this.taxTriggerTarget.classList.remove("d-none")
  }

  showClientPriceForm() {
    this.clientPriceTriggerTarget.classList.add("d-none")
    this.clientPriceFormTarget.hidden = false
  }

  hideClientPriceForm() {
    this.clientPriceTriggerTarget.classList.remove("d-none")
    this.clientPriceFormTarget.hidden = true
  }
}
