import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    // this.bindEvents();
    this.autoremoveFiles();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  autoremoveFiles() {
    this.dropZone.on('addedfile', function (file) {
      if (this.files.length > 1) {
        this.removeFile(this.files[0]);
      }
      document.getElementsByClassName('submitButton')[0].classList.remove('disabled');

    });
  }

  submitFile() {
    setTimeout(() => {
      this.dropZone.files[0].accepted && createDirectUploadController(this.dropZone, this.dropZone.files[0], this).start();
    }, 500);
  }
  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }


  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get targetElement() {
    return this.data.get("target-element");
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get acceptedFilesMessage() {
    return this.data.get("acceptedFilesMessage") || true;
  }
}

class DirectUploadController {
  constructor(source, file, mainController) {
    this.directUpload = createDirectUpload(file, mainController.url);
    this.source = mainController.dropZone;
    this.file = file;
    this.mainController = mainController
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {

        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.mainController.inputTarget.name;
    insertAfter(input, this.mainController.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.mainController.dropZone.emit("error", this.file, error);
    this.mainController.dropZone.emit("complete", this.file);
    document.getElementsByClassName('submitButton')[0].classList.add('disabled');
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.mainController.dropZone.emit("success", this.file);
    this.mainController.dropZone.emit("complete", this.file);
    setTimeout(() => {
      document.getElementById("submitBtn").click(); //triggering manual submit because local: false doesn't fire through js
    }, 500);
  }
}

function createDirectUploadController(source, file, mainController) {
  return new DirectUploadController(source, file, mainController);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  const el = document.getElementById(controller.targetElement);
  const dropzone = new Dropzone(el, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    dictInvalidFileType: controller.acceptedFilesMessage,
    addRemoveLinks: controller.addRemoveLinks,
    thumbnailMethod: "contain",
    autoQueue: false,
    clickable: document.getElementsByClassName('manual-dropzone-trigger')[0],
    processing: (file) => {
      document.getElementById('dropzone-submit').classList.remove("disabled");
    },
    previewTemplate: document.getElementById('preview-template').innerHTML

  });
  dropzone.on("error", (file, message) => {
    document.getElementById('dropzone-submit').classList.add("disabled");
  })
  return dropzone;
}
