import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "download", "customPeriod", "select", "from", "to", "format", "periodSelect" ]

  connect() {
    this.downloadState()
  }

  downloadState(e) {
    this.downloadTarget.classList.add("disabled")
    this.customPeriodTarget.classList.add("d-none")

    if ( this.selectTarget.value == "custom" ) {
      this.customPeriodTarget.classList.remove("d-none")
    } else if ( this.selectTarget.value ) {
      const data = this.selectTarget.querySelector("option:checked").dataset
      this.fromTarget.value = data.from.replaceAll('"', "")
      this.toTarget.value = data.to.replaceAll('"', "")
      if ( e && e.target == this.selectTarget ) {
        this.periodSelectTarget.submit()
      }
    } else {
      this.fromTarget.value = ""
      this.toTarget.value = ""
      if ( e && e.target == this.selectTarget ) {
        this.periodSelectTarget.submit()
      }
    }
    
    if ( this.formatTarget.value ) {
      this.downloadTarget.classList.remove("disabled")
    }
  }

}