import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["invoiceType"]

  connect() {
    this.setCheckboxesTypes()
    this.listenForNewFields()
    this.setDisabledCheckboxes()
    $('#bb-form').submit((e) => {
      this.enableCheckboxes()
    })
  }

  listenForNewFields() {
    $(document).ready(() => {
      $('#billing_book_form').on('cocoon:after-insert', (e, insertedItem) => {
        this.setCheckboxesTypes()
      })
    })
  }

  handleCheck(e) {

    var invtype = $(e.target).data('invoiceType')
    var rest_boxes = this.findSimilarCheckboxes($(e.target)).toArray()

    if (rest_boxes.length > 0) {
      if ($(e.target).prop('checked')) {
        rest_boxes.forEach((el) => {
          el.disabled = false
          $(el).prop('checked', false)
        })

        e.target.disabled = true
      } else {
        $(rest_boxes[0]).prop('checked', true)
      }
    }
  }

  handleSelect(e) {
    this.changeCheckBoxesInvType(e.target)
  }

  changeCheckBoxesInvType(el) {
    var closestCheckbox = this.closestCheckbox(el)
    var invType = closestCheckbox.data('invoiceType');
    if (invType != $(el).val()) {

      if (!(this.checkedBoxes(closestCheckbox).includes(true))) {
        var otherCheckboxes = this.findSimilarCheckboxes(closestCheckbox)
        otherCheckboxes.first().prop('checked', true)
      }
    }
    closestCheckbox.data('invoiceType', $(el).val())
    if (!(this.checkedBoxes(closestCheckbox).includes(true))) {
      closestCheckbox.prop('checked', true)
      closestCheckbox.prop('disabled', true)
    } else {
      closestCheckbox.prop('checked', false)
      closestCheckbox.prop('disabled', false)
    }
  }

  excludeElement(collection, el) {
    return collection.filter((i, e) => { return e != el })
  }

  checkedBoxes(el) {
    return this.findSimilarCheckboxes(el).map((i, ch) => { return $(ch).prop('checked') }).toArray()
  }


  findSimilarCheckboxes(target) {
    var invType = target.data('invoiceType')
    return $('[type=checkbox]').filter((i, el) => { return (($(el).data('invoiceType') == invType) && (el != target[0])) })
  }

  enableCheckboxes() {
    document.querySelectorAll('#bb-form [type=checkbox]').forEach((el) => {
      el.disabled = false
    })
  }
  setDisabledCheckboxes() {
    Array.from(document.querySelectorAll('#bb-form [type=checkbox]')).filter((i, el) => {
      return el.checked
    }).forEach((el) => {

      el.disabled = true
    })
  }


  setCheckboxesTypes() {
    this.selectBoxes().each((i, el) => {
      this.closestCheckbox(el).data('invoiceType', this.selectBoxVal(el))
    })
  }

  closestCheckbox(el) {
    return $(el).closest('.nested-fields').find('[type=checkbox]')
  }

  selectBoxVal(selectBox) {
    return $(selectBox).find('select').find(":selected").val()
  }

  selectBoxes() {
    return $('.invoice_type_selection')
  }

}

