import { Controller } from "@hotwired/stimulus"
import Splide from '@splidejs/splide'
import Rails from "@rails/ujs"
import "@splidejs/splide/dist/css/splide.min.css"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["minutesSliders"]

  connect() {
    this.minutesSlidersTargets.map(slider => {
      this.updateMinutesSliderLabel(slider)
    })
    this.initLogosCarousel()
    this.initVideosCarousels()
    window.$ = $
  }

  scrollToSection(e) {
    e.preventDefault()
    const targetId = e.target.href.split("#")[1]
    if (targetId == 'top') {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      document.getElementById(targetId).scrollIntoView({behavior: 'smooth'})
    }
  }

  updateMinutesSliderLabel(e) {
    const slider = e.type == "range" ? e : e.target
    const label = slider.parentNode.querySelector(".minutes-slider-label")
    label.innerText = slider.value + " min"
  }

  handleSliderLimitTooltip(e) {
    const slider = e.target
    $('[data-bs-toggle="tooltip"]').tooltip('hide')
    if (slider.value === "1" || slider.value === slider.max) {
      $(slider).tooltip('dispose')
      const myPlacement = slider.value === "1" ? "left" : "right"
      $(slider).tooltip({placement: myPlacement, popperConfig: {eventsEnabled: false}})
      $(slider).tooltip('show')
      setTimeout(() => this.hideSliderTooltip(slider), 2400)
    }
  }

  hideSliderTooltip(slider) {
    $(slider).tooltip('hide')
  }

  initLogosCarousel() {
    if (document.getElementById("logos-carousel")) {
      new Splide("#logos-carousel", {
        type: "loop",
        perPage: 4,
        height: '64px',
        perMove: 1,
        pagination: false,
        autoplay: true,
        interval: 3000,
        breakpoints: {
          576: {
            perPage: 1
          }
        }
      }).mount()
    }
  }

  initVideosCarousels() {
    document.querySelectorAll("#videos-carousel").forEach(elem => {
      const carousel = new Splide(elem, {
        type: "loop",
        perPage: 1,
        perMove: 1,
        autoHeight: true,
        pagination: true,
        breakpoints: {
          576: {
            perPage: 1
          }
        }
      }).mount()

      carousel.on("move", () => {
        const iframes = [...carousel.root.querySelectorAll("iframe")]
        iframes.map(iframe => {
          const source = iframe.src
          iframe.src = source
        })
      })
    })
  }

  submitTemplateForm(e) {
    Rails.fire(e.target.closest("form"), "submit")
  }

  changeSelectedAndSubmit(e) {
    const element = e.target.parentNode.querySelector("input[type='checkbox']")
    element.checked = !element.checked
    Rails.fire(element.closest("form"), "submit")
  }

  handleAddonSelect(e) {
    const url = e.target.dataset.url
    const addonId = e.target.dataset.id
    const psgbtId = e.target.dataset.psgbtId
    let fd = new FormData();
    fd.append("preproposal_setting_generic_budget_template[id]", psgbtId);
    fd.append("preproposal_setting_generic_budget_template[addon_id]", addonId);
    Rails.ajax({
      url: url,
      type: "POST",
      data: fd
    })
  }

  replaceVideo(e) {
    const videoId = e.target.dataset.videoId
    const container = e.target.parentNode
    container.innerHTML = `<iframe src="https://www.youtube.com/embed/${videoId}?rel=0" width="88%" height="315"
      style="border: 0;" allowfullscreen></iframe>`
  }

}
