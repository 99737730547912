import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dropdown", "amount", "percentage", "trigger"]
    static values = {
        discountType: String,
        amount: Number
    }

    connect() {
        this.handleDiscountInputs()
        this.setTriggerText()
    }

    handleDiscountInputs() {
        this.amountTarget.hidden = true
        this.percentageTarget.hidden = true
        this.discountTypeValue = this.element.querySelector(".discount-radios input[type=radio]:checked").value

        if (this.discountTypeValue == "amount") {
            this.amountValue = Number(this.amountTarget.querySelector("input").value)
            this.amountTarget.hidden = false
        } else if (this.discountTypeValue == "percentage") {
            this.amountValue = Number(this.percentageTarget.querySelector("input").value)
            this.percentageTarget.hidden = false
        }
    }

    toggleDiscountDropdown() {
        this.dropdownTarget.hidden = !this.dropdownTarget.hidden
        this.dispatch("popNumPadStatus", { detail: { numPadStatus: this.dropdownTarget.hidden } })
    }

    closeOnOutsideClick(e) {
        if (!this.element.contains(e.target)) { this.dropdownTarget.hidden = true }
        this.dispatch("popNumPadStatus", { detail: { numPadStatus: this.dropdownTarget.hidden } })
    }

    setTriggerText() {
        let defaultText = this.triggerTarget.dataset.defaultText

        if (this.discountTypeValue == "amount" && this.amountValue > 0) {
            defaultText = "- " + this.amountValue + "€"
            defaultText += this.resetButton()
        } else if (this.discountTypeValue == "percentage" && this.amountValue > 0) {
            defaultText = "- " + this.amountValue + "%"
            defaultText += this.resetButton()
        }

        this.triggerTarget.innerHTML = defaultText
    }

    resetButton() {
        return `<i class="ms-2 text-danger fa-regular fa-circle-xmark" data-action="click->base-discount-widget#reset"></i>`
    }

    reset() {
        this.amountTarget.querySelector("input").value = 0
        this.amountValue = 0
        this.percentageTarget.querySelector("input").value = 0
        this.percentageValue = 0
        this.submit()
    }

    submit() {
        this.setTriggerText()
        // this.updateLine()
        // this.updateHiddenInput()
        this.dropdownTarget.hidden = true
        this.dispatch("popDiscountValues", { detail: { amount: this.amountValue, type: this.discountTypeValue } })

    }


}
