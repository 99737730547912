import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hideOnClose", "showOnClose"]

  closeForm(e) {
    this.hideOnCloseTarget.classList.add("d-none")
    this.showOnCloseTarget.classList.remove("d-none")
  }

}
