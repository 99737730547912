import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["bookSeries", "bookNum", "billingBookSelect"]

  connect() {
    this.setBillingBookData(this.billingBookSelectTarget.value)
  }

  billingBookChanged(e) {
    this.setBillingBookData(e.target.value)
  }

  setBillingBookData(id) {
    const elem = document.querySelector(`.js-billing-book-data-${id}`)
    if (elem) {
      const data = JSON.parse(elem.dataset.value)
      this.bookSeriesTarget.value = data["series"]
      this.bookNumTarget.value = data["num"]
    }
  }

}
