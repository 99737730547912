import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["productionStageShoot", "basicCostDetails", "addonCheckboxes"]

  connect() {
    this.handleDuration()
    this.handleServices()
  }

  handleDuration() {
    const durationInput = document.getElementById("budget_template_duration_in_minutes")
    if ( durationInput ) {
      const durationWrapper = durationInput.closest(".duration-wrapper")
      
      durationInput.disabled = true
      durationWrapper.hidden = true

      if ( this.productionStageShootTarget.checked ) {
        durationInput.disabled = false
        durationWrapper.hidden = false
      }
    }
  }

  handleServices() {
    document.querySelectorAll("input[id*='budget_template_production_stage_']").forEach(input => {
      const services = input.parentElement.querySelector('.services')
      if ( services ) {
        const checkboxesLength = services.querySelectorAll("input[type='checkbox']").length
        if ( checkboxesLength ) {
          services.hidden = !input.checked
        }
      }
    })
  }

  submitForm(event) {
    const form = event.target.closest('form')
    Rails.fire(form, "submit")
  }

  toggleBasicCost() {
    this.basicCostDetailsTargets.map(item => { item.hidden = !item.hidden })
  }

  toggleAddon(event) {
    const id = event.target.closest("span").id
    document.querySelectorAll(`.addon-${id}`).forEach(item => { item.hidden = !item.hidden })
  }

  handleAddonCheckboxes() {
    let selectedAddons = []
    let form = document.getElementById("budget-templates-show-form")
    this.addonCheckboxesTargets.map(checkbox => {
      if ( checkbox.checked ) { selectedAddons.push(checkbox.value) }
    })
    document.getElementById("selected_addons").value = selectedAddons
    Rails.fire(form, "submit")
  }
}