import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    searchUrl: String
  }

  static targets = ["input", "dropdown"]

  search() {
    const value = this.inputTarget.value

    if ( value.length > 2 ) {
      fetch(this.searchUrlValue + `?num=${value}`)
        .then((response) => response.json())
        .then((data) => {
          if ( data.length > 0 ) {
            this.createMenu(data)
            this.showDropdown()
          }
        })
    } else {
      this.hideDropdown()
    }
  }

  createMenu(data) {
    this.dropdownTarget.html = ""
    data.map(item => {
      const html = this.option(item.text, item.value)
      this.dropdownTarget.insertAdjacentHTML("beforeend", html)
    })
  }

  option(text, value) {
    return `
      <div class="dropdown-item cursor-pointer" data-value="${value}"
        data-action="click->searchable-input#itemSelected">
        <div class="service-name fw-bold pointer-events-none">
          ${text}
        </div>
      </div>
    `
  }

  showDropdown() {
    this.dropdownTarget.classList.add("show")
  }

  hideDropdown() {
    this.dropdownTarget.classList.remove("show")
  }

  handleClickOutside(event) {
    if ( !this.element.contains(event.target) ) {
      this.hideDropdown()
    }
  }

  itemSelected(event) {
    const value = event.target.dataset.value
    this.inputTarget.value = value
    this.hideDropdown()
  }
}
