import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static targets = ['selectEmail']

  connect() {
    if (this.hasSelectEmailTarget) {
      const select = this.slimSelectElement(this.selectEmailTarget)
      const inputEmailField = this.element.querySelector('.js-select-email-group .ss-search input[type=search]')
      const inputEmailAddableBtn = this.element.querySelector('.js-select-email-group .ss-addable')

      if (inputEmailField) {
        inputEmailField.addEventListener('keyup', (e) => {
          e.target.dataset.searchQuery = e.target.value
          if (e.key === 'Enter' && inputEmailAddableBtn) inputEmailAddableBtn.click()
        })

        inputEmailField.addEventListener('keydown', e => {
          if (e.key === 'Tab') {
            e.target.value = e.target.dataset.searchQuery
            if (inputEmailAddableBtn) inputEmailAddableBtn.click()
            e.target.value = e.target.dataset.searchQuery = ''
          }
        })
      }
    }

  }

  slimSelectElement(element) {
    return new SlimSelect({
      select: element,
      settings: {
        placeholderText: 'Search or add new',
      },
      events: {
        addable: function (value) {
          if (value.trim().replace("@", "") === "" || value.split("@").length !== 2) {
            return false
          }

          return value.trim().toLowerCase()
        }
      }
    })
  }

  validateEmail(e) {
    let error = document.getElementById('error')
    let flag = false
    let elements = document.getElementsByClassName('ss-value-text')

    for (let element of elements) {
      if (!this.emailRegexCheck(element.innerText)) {
        element.closest('.ss-value').style.backgroundColor = "red"
        document.querySelector('#email_to .ss-multi-selected').style.border = '1px solid #dcdee2'
        flag = true
      }
    }

    if (flag) {
      error.classList.remove('d-none')
      e.preventDefault()
      return true
    }

    if (!!document.querySelector('#email_to .ss-values .ss-disabled')) {
      error.classList.remove('d-none')
      document.querySelector('#email_to .ss-multi-selected').style.border = '1px solid red'
      e.preventDefault()
      return true
    }
    document.getElementById('error').classList.add('d-none')
  }

  emailRegexCheck(email) {
    let emailReg = /^\w?([\.\-\+]?\w+)+\-*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
    return email.match(emailReg)
  }

}
