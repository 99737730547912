import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "amountInput", "taxAmountInput", "amount", "nights", "tax", "submit", "billingBook" ]
  static values = {
    taxes: Object
  }

  connect() {
    this.setSelectSeason()
    if (this.element.id === 'accomondation_tax_modal' || this.element.dataset.newRecord === 'true') {
      this.taxAmountInputTarget.value = this.taxTarget.selectedOptions[0].dataset.num
    }
    this.calculateAmount()
  }

  calculateAmount() {
    const account = document.getElementById('invoice_account_id').value
    const billingBook = this.billingBookTarget.selectedOptions[0].value
    const nights = this.nightsTarget.value || 0
    // const tax = this.taxTarget.selectedOptions[0].dataset.num || 0
    const tax = this.taxAmountInputTarget.value
    const amount = (nights * tax).toFixed(2)
    this.amountInputTarget.value = amount
    this.amountTarget.innerText = amount

    this.submitTargets.forEach( btn => {
      btn.disabled = amount <= 0 || tax < 0.5 || billingBook == "" || !account
    })
  }

  setAccomondationTax() {
    this.taxAmountInputTarget.value = this.taxTarget.selectedOptions[0].dataset.num || 0
    this.calculateAmount()
  }

  setSelectSeason() {
    // const season = document.querySelector("input[name='invoice[accomondation_season]']:checked").value === "winter" ? 0 : 1
    const season = document.querySelector("#invoice_accomondation_season").value === "winter" ? 0 : 1
    this.taxTarget.querySelectorAll("option").forEach(option => {
      if ( option.value != "" ) {
        option.dataset.num = this.taxesValue[option.value][season]
      }
    })
    // this.calculateAmount()
    // this.setAccomondationTax()
  }

}
