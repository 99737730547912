import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "linkBtn" ]

  connect() {
    const elem = document.querySelector(".js-public-checkbox")
    if (elem) {
      this.buildUrlFromCheckbox(elem)
    }
  }

  publicCheckboxChanged(e) {
    this.buildUrlFromCheckbox(e.target)
  }

  buildUrlFromCheckbox(elem) {
    const url = new URL(this.linkBtnTarget.dataset.url)
    if (elem.checked) url.searchParams.set("public", elem.checked)
    this.linkBtnTarget.href = url
  }

  linkClicked(e) {
    e.target.innerText = "View Offer"
    const elem = document.querySelector(".js-public-checkbox-container")
    if (elem) {
      elem.remove()
    }
  }
}
