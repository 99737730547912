import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // this.setActiveByUrlHash()
    if (this.projectIdChanged()) {
      this.deleteCookie("last_project_tab")
    }
    this.setNewProjectCookie()
    this.setActiveTabFromCookie()
    document.querySelectorAll(".js-nav-link").forEach(element => {
      element.addEventListener('click', (element) => this.handleTabClick(element))
    })
  }

  // If we need to display other than default tab just pass anchor in the url.
  // Example: url#pills-project-info
  setActiveByUrlHash() {
    let activeId = "pills-crew-list"
    if ( window.location.hash ) {
      activeId = window.location.hash.split('#')[1]
    }
    document.getElementById(activeId + "-tab").classList.add("active")
    document.getElementById(activeId).classList.add("show", "active")
  }

  projectIdChanged() {
    if (this.getCookie("last_project_id")) {
      const currProjectID = document.querySelector(".js-project-data").dataset.projectId
      const lastProjectID = this.getCookie("last_project_id")
      return currProjectID != lastProjectID
    }
    return false
  }

  setNewProjectCookie() {
    const currProjectID = document.querySelector(".js-project-data").dataset.projectId
    document.cookie = `last_project_id=${currProjectID}; path=/`
  }

  setActiveTabFromCookie() {
    if (this.getCookie("last_project_tab")) {
      const tabID = this.getCookie("last_project_tab")
      const defaultTab = document.querySelector('.tab-pane.show.active')
      const defaultNavLink = document.querySelector(`#${defaultTab.id}-tab`)
      defaultTab.classList.remove("show", "active")
      defaultNavLink.classList.remove("active")
      document.querySelector(tabID).classList.add("show", "active")
      document.querySelector(`${tabID}-tab`).classList.add("active")
    }
  }

  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  deleteCookie(name) {
    if (this.getCookie(name)) {
      document.cookie = `${name}= ;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
    }
  }

  handleTabClick(element) {
    if (element.target.hash) {
      document.cookie = `last_project_tab=${element.target.hash}; path=/`
    }
  }
}
