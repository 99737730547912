import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["supplier"]
  static values = {url: String, projectExpense: String, inline: String, projectSection: String}

  connect() {
    if ( this.projectExpenseValue ) {
      this.supplierChanged()
    }
  }

  supplierChanged() {
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      dataType: "script",
      data: `supplier_id=${this.supplierTarget.value}&project_expense_id=${this.projectExpenseValue}` + 
        `&inline=${this.inlineValue}&project_section=${this.projectSectionValue}`
    })
  }
}
