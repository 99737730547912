import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "title", "titleForm", "proposalItemsSortable" ]

  connect() {
    this.initSortable(this.proposalItemsSortableTarget)
  }

  showTitleForm() {
    this.titleTarget.hidden = true
    this.titleFormTarget.hidden = false
  }

  initSortable(element) {
    const sortable = new Sortable(element, {
      animation: 150,
      handle: ".sort-handler",
      onEnd: (event) => { 
        const reorderUrl = event.to.dataset.reorderUrl
        fetch(reorderUrl + `?ids=${sortable.toArray()}`, {
          method: "PUT",
          headers: {
            "X-CSRF-Token": this.csrfToken()
          }
        })
      }
    })
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
}
