import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "repeatEvery", "defaultAfter", "repeatEveryNo", "afterMonthly", "startDate", "emailRecipients" ]

  connect() {
    this.handleVisibleFields()
    this.saveOnInputChange()
  }

  handleVisibleFields() {
    document.querySelectorAll("[data-show-on]").forEach(elem => { elem.hidden = true })
    document.querySelectorAll(`[data-show-on~=${this.repeatEveryTarget.value}]`)
      .forEach(elem => { elem.hidden = false })
    this.moveRepeatEveryNoRespectively()
  }

  moveRepeatEveryNoRespectively() {
    this.defaultAfterTarget.insertAdjacentElement("afterend", this.repeatEveryNoTarget)
    if ( this.repeatEveryTarget.value == "monthly" ) {
      this.afterMonthlyTarget.insertAdjacentElement("afterend", this.repeatEveryNoTarget)
    }
  }

  saveOnInputChange() {
    ["change", "changeDate"].map(eventName => {
      this.element.querySelectorAll("input, select").forEach(elem => {
        if (elem.id === 'recurring_schedule_email_recipients') return
        elem.addEventListener(eventName, (event) => {
          this.element.requestSubmit()
        })

      })
    })
  }
  validateInput() {
    let valid = false
    if (this.startDateTarget.value) {
      this.startDateTarget.classList.remove('is-invalid')
      valid = true
    } else {
      this.startDateTarget.classList.add('is-invalid')
    }
    document.querySelector('#recurringModal input[type="submit"]').disabled = !this.startDateTarget.value
    return valid
  }

  validateEmailsOnSubmit(e = nil) {
    let error = document.getElementById('recurring_schedule_errors')
    error.hidden = true
    if (document.getElementById('send-email-switch').checked && this.hasEmailRecipientsTarget && !this.emailRecipientsTarget.value) {
      this.emailRecipientsTarget.closest('.row').querySelector('.ss-multi-selected').style.border = "1px solid red"
      error.innerText = error.dataset.emailRecipientsError
      error.hidden = false
      if (e) e.preventDefault()
    } else if (this.hasEmailRecipientsTarget) {
      this.emailRecipientsTarget.closest('.row').querySelector('.ss-multi-selected').style.border = ""
    }

  }

}
