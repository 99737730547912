import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.accountIdChanged()) {
      this.deleteCookie("last_account_tab")
    }
    this.setNewAccountCookie()
    this.setActiveTabFromCookie()
    this.handleExternalTabClick()
  }

  accountIdChanged() {
    if (this.getCookie("last_account_id")) {
      const currAccountID = document.querySelector(".js-account-data").dataset.accountId
      const lastAccountID = this.getCookie("last_account_id")
      return currAccountID != lastAccountID
    }
    return false
  }

  setNewAccountCookie() {
    const currAccountID = document.querySelector(".js-account-data").dataset.accountId
    document.cookie = `last_account_id=${currAccountID}; path=/`
  }

  setActiveTabFromCookie() {
    if (this.getCookie("last_account_tab")) {
      const tabID = this.getCookie("last_account_tab")
      const defaultTab = document.querySelector('.tab-pane.show.active')
      const defaultNavLink = document.querySelector(`#${defaultTab.id}-tab`)
      defaultTab.classList.remove("show", "active")
      defaultNavLink.classList.remove("active")
      document.querySelector(tabID).classList.add("show", "active")
      document.querySelector(`${tabID}-tab`).classList.add("active")
    }
  }

  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  deleteCookie(name) {
    if (this.getCookie(name)) {
      document.cookie = `${name}= ;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
    }
  }

  handleTabClick(e) {
    if (e.target.hash) {
      document.cookie = `last_account_tab=${e.target.hash}; path=/`
    }
  }
  handleExternalTabClick() {
    let anchor = window.location.hash.replace("#","") // Get the target tab from the url
    if (!anchor) return
    let tab = document.getElementById(anchor)
    if (tab) {
      tab.dispatchEvent(new Event('click'))
      window.location.href = window.location.origin + window.location.pathname // Remove the target tab name from the url
    }
  }
}
