import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['notifyNewTosModal', 'newTosNotification']

  connect() {
    if (this.hasNotifyNewTosModalTarget) this.showNotifyNewTosModal()
  }

  hideNewTosNotification() {
    const url = this.newTosNotificationTarget.dataset.notificationStatusUrl

    fetch(url, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": this.csrfToken()
      },
      body: JSON.stringify({
        tenant_notification: {
          notify_on_new_tos: false
        }
      })
    }).then( response => response.json())
      .then( data => {
        if (data.success == 'true') {
          this.newTosNotificationTarget.hidden = true
        } else {
          console.log(data.error)
        }
    })
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
}
