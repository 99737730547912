import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  downloadPdf() {
    this.notification('notice', 'Your proposal has been downloaded!')
  }

  notification(key, message) {
    const elem = `<div data-controller='flash' data-flash-key='${key}' \
      data-flash-value='${message}'></div>`
    document.body.insertAdjacentHTML('beforeend', elem)
  }
}
