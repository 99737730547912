import { Controller } from "@hotwired/stimulus"
const confetti = require('canvas-confetti')

export default class extends Controller {
  connect() {
    this.congrats()
  }

  congrats() {
    confetti({
      particleCount: 100,
      spread: 90,
      origin: { y: 0.6 }
    })
  }
}
