import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    searchUrl: String,
    resourceId: String,
    extraParam: String
  }

  static targets = [ "term", "results" ]

  search() {
    if ( this.termTarget.value.length > 2 ) {
      const url = this.searchUrlValue + `?search=${this.termTarget.value}&resource_id=${this.resourceIdValue}` +
        `&extra_param=${this.extraParamValue}`
      fetch(url, {
        method: "GET",
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          "Accept": "text/html",
        }
      }).then((response) => response.text())
        .then((data) => {
          this.resultsTarget.innerHTML = data
        })
    } else {
      this.resultsTarget.innerHTML = ""
    }
  }

  close(e) {
    if ( e.code == "Escape" ) {
      e.target.closest(".editable").hidden = true
      e.target.closest(".editable").previousElementSibling.hidden = false
    }
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
}