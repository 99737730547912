import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["warningElem", "nameField", "vatRate", "vatExemption", "fields", "productField", "serviceField",
    "specialCategory"]
  MAX_LENGTH = 75;

  connect() {
    (this.nameFieldTarget.value.length > this.MAX_LENGTH) ? this.showWarning() : this.hideWarning()
    this.handleExemption()
    this.handleForm()
    this.handleFields()
  }

  skipToNext() {
    document.getElementById("skip_products").click();
  }

  continueToUrl() {
    const el = document.querySelector("input[name='redirect_path_success']")
    const page = '?step=' + String(Number(el.value.split('?step=')[1]) + 1)
    const newurl = el.value.split('?step=')[0] + page
    el.value = newurl
    document.getElementById("submitBtn").click();
  }

  nameChanged(e) {
    (e.target.value.length > this.MAX_LENGTH) ? this.showWarning() : this.hideWarning()
  }

  showWarning() {
    if (!this.warningElemTarget.classList.contains("d-none")) return
    this.warningElemTarget.classList.remove("d-none")
  }

  hideWarning() {
    if (this.warningElemTarget.classList.contains("d-none")) return
    this.warningElemTarget.classList.add("d-none")
  }

  handleExemption() {
    this.vatExemptionTarget.hidden = this.vatRateTarget.value != 0 || !this.vatRateTarget.value
  }

  handleForm() {
    const inputChecked = this.element.querySelectorAll("input[type=radio]:checked").length != 1
    this.fieldsTarget.hidden = inputChecked
    if (this.element.querySelector('#continueBtn') || this.element.querySelector('#saveAndContinueBtn')) {
      this.handleFormButtons(inputChecked);
    }

  }

  handleFormButtons(inputChecked) {
    this.element.querySelector('#saveAndContinueBtn').hidden = inputChecked
    this.element.querySelector('#continueBtn').hidden = !inputChecked
  }

  handleServiceSelected() {
    const el = document.querySelector("#service_lines_form")
    const url = el.dataset.url
    Rails.ajax({
      url: url,
      type: "POST",
      data: $("#service_lines_form :input[value!=patch]").serialize(),
      success: (result) => {
        Rails.$("#service_line")[0].innerHTML = result.html;
      }
    })
  }

  handleFields() {

    this.productFieldTargets.map(t => { t.hidden = true })
    this.serviceFieldTargets.map(t => { t.hidden = true })
    this.handleSpecialCategoryDefaults()
    let index = 0
    const selectedType = this.element.querySelector("input[type=radio]:checked")?.value

    if (selectedType && selectedType == "service") {
      this.serviceFieldTargets.map(t => {
        t.hidden = false
        if (index < 3) {
          t.querySelector(".collapse").classList.add("show")
        }
        index++
      })
    } else if (selectedType && ["product", "commodity"].includes(selectedType)) {
      this.productFieldTargets.map(t => {
        t.hidden = false
        if (index < 3) {
          t.querySelector(".collapse").classList.add("show")
        }
        index++
      })
    }
  }

  handleSpecialCategoryDefaults() {
    // Special Rules
    // 5.2 Product category = category1_1
    // 5.2 Service category = category1_3
    const currentServiceLineType = document.querySelector("input[type=radio]:checked")?.value
    const select_5_2 = this.specialCategoryTargets.find(e => { return e.dataset.invoiceType == "5.2" })
    if (currentServiceLineType == "product" && select_5_2.value == "category1_3") {
      select_5_2.value = "category1_1"
    } else if (currentServiceLineType == "service" && select_5_2.value == "category1_1") {
      select_5_2.value = "category1_3"
    }
  }
}
