import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { Datepicker } from 'vanillajs-datepicker';


export default class extends Controller {

  static targets = [ "container" ]

  connect() {
    this.initDatepickers()
  }

  disconnect() {
    this.clearDatepickers()
  }

  initDatepickers() {
    const elements = this.containerTarget.querySelectorAll('.datepicker-input');
    [...elements].forEach((elem) => {
      if (elem.datepicker === undefined) {
        const datepicker = new Datepicker(elem, {
            showOnFocus: true,
            autohide: true,
            format: "yyyy-mm-dd",
            daysOfWeekDisabled: [0, 6],
            minDate: elem.dataset.minDate
          }
        )
      }
    })
  }

  clearDatepickers() {
    const elements = this.containerTarget.querySelectorAll('.datepicker-input');
    [...elements].forEach((elem) => {
      elem.datepicker.destroy()
    })
  }

  submitForm(e) {
    const elem = this.containerTarget.querySelector("#js-date-changed")
    if (elem) {
      elem.value = e.target.dataset.dateLabel
    }
    Rails.fire(e.target.closest("form"), "submit")
  }

}
