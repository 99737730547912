import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "box",
    "clearSelected",
    "selectAll",
    "from",
    "to",
    "filtersForm",
    "export",
    "visibleInvoices",
    "allMatchedInvoices",
    "togglePaymentStatus",
    "sortColumn",
    "sortDirection",
    "editButton"
  ]

  connect() {
    this.customDropdownBehaviorFix()
    if ( this.hasFiltersFormTarget ) {
      this.allIds = JSON.parse(this.filtersFormTarget.dataset.allIds)
    }
  }

  export(event) {
    event.preventDefault()
    const exportUrl = event.target.dataset.exportUrl
    const type = event.target.dataset.type
    let selectedIds = []
    if ( this.invoicesSelected == "all" ) {
      selectedIds = this.allIds
    } else {
      selectedIds = this.boxTargets.filter(c => c.checked).map(c => c.value)
    }
    if ( selectedIds.length == 0 ) {
      notification("error", event.target.dataset.error)
      return
    }
    if ( type == "pdf" && this.importedCount() > 0 ) {
      this.createAndSubmitExportForm(exportUrl, selectedIds, type)
    } else {
      this.createAndSubmitExportForm(exportUrl, selectedIds, type)
    }
  }

  importedCount() {
    let count = 0
    this.boxTargets
      .filter(c => c.checked)
      .reduce((prev, curr) => { if ( curr.dataset.status == "imported" ) { count += 1 } }, count)
    return count
  }

  handleMassCheck(event) {
    this.boxTargets.map(checkbox => { checkbox.checked = event.target.checked })
    this.handleSelected()
  }

  toggleDropdown(event) {
    event.target.parentNode.classList.toggle("open")
  }

  handleSelected() {
    const allBoxes = this.boxTargets.length
    const checkedBoxes = this.boxTargets.filter(c => c.checked).length
    this.clearSelectedTarget.hidden = checkedBoxes == 0
    this.selectAllTarget.indeterminate = false
    this.visibleInvoicesTarget.hidden = true
    this.allMatchedInvoicesTarget.hidden = true
    this.invoicesSelected = ""
    if ( checkedBoxes > 0 && allBoxes != checkedBoxes ) {
      this.selectAllTarget.indeterminate = true
    } else if ( checkedBoxes > 0 && allBoxes == checkedBoxes ) {
      if ( this.allIds.length > 20 ) {
        this.visibleInvoicesTarget.innerHTML = this.visibleInvoicesTarget.innerHTML.replace("XX", checkedBoxes)
        this.visibleInvoicesTarget.hidden = false
      }
      this.selectAllTarget.checked = true
    }
  }

  unselectAll(event) {
    event.preventDefault()
    this.boxTargets.map(checkbox => { checkbox.checked = false })
    this.selectAllTarget.checked = false
    this.invoicesSelected = ""
    this.handleSelected()
  }

  closeOnOutsideClick(event) {
    if ( !event.target.closest(".custom-dropdown") &&
      !event.target.parentNode.classList.value.includes("datepicker") ) { this.closeCustomDropdown() }
    if ( !event.target.closest("[id^=payment-date]") &&
      !event.target.parentNode.classList.value.includes("datepicker") ) { this.closeAllPaymentDates() }
  }

  closeCustomDropdown() {
    if ( document.querySelector(".custom-dropdown") ) {
      document.querySelector(".custom-dropdown").classList.remove("open")
    }
  }

  selectAll() {
    this.boxTargets.map(checkbox => { checkbox.checked = true })
    this.selectAllTarget.checked = true
    this.handleSelected()
  }

  customDropdownBehaviorFix() {
    [...document.querySelectorAll("[data-bs-toggle='dropdown']")].map(elem => {
      elem.addEventListener("click", () => { this.closeCustomDropdown() })
    })
  }

  fillFromTo(event) {
    event.preventDefault()
    this.fromTarget.value = event.target.dataset.from.replaceAll('"', "")
    this.toTarget.value = event.target.dataset.to.replaceAll('"', "")
    this.filtersFormTarget.submit()
  }

  submitForm(event) {
    Rails.fire(event.target.closest("form"), "submit")
  }

  showForm(event) {
    event.target.hidden = true
    event.target.nextElementSibling.hidden = false
  }

  closeForm(event) {
    const invoiceId = event.target.dataset.invoiceId
    const parent = event.target.dataset.parent
    const elem = document.getElementById(`payment-${parent}-${invoiceId}`)
    elem.querySelector("form").hidden = true
    elem.querySelector("span").hidden = false
  }

  closeAllPaymentDates() {
    [...document.querySelectorAll("[id^=payment-date-]")].map(elem => {
      elem.querySelector("span").hidden = false
      elem.querySelector("form").hidden = true
    })
  }

  invoicesSelectedAll() {
    this.invoicesSelected = "all"
    this.visibleInvoicesTarget.hidden = true
    this.allMatchedInvoicesTarget.querySelector(".js-all-matched-text").innerText =
      this.allMatchedInvoicesTarget.querySelector(".js-all-matched-text").innerText.replace("XX", this.allIds.length)
    this.allMatchedInvoicesTarget.hidden = false
  }

  markAs(event) {
    event.preventDefault()
    const url = event.target.dataset.url
    let selectedIds = []
    if ( this.invoicesSelected == "all" ) {
      selectedIds = this.allIds
    } else {
      selectedIds = this.boxTargets.filter(c => c.checked).map(c => c.value)
    }
    if ( selectedIds.length == 0 ) {
      notification("error", event.target.dataset.error)
      return
    }
    if ( selectedIds.length > 20 ) {
      notification("error", "Για την συγκεκριμένη ενέργεια μπορείτε να επιλέξετε μέχρι 20 τιμολόγια")
      return
    }
    Rails.ajax({
      type: "POST",
      url: url,
      dataType: "script",
      data: `ids=${selectedIds}`
    })
  }

  createAndSubmitExportForm(action, selectedIds, type) {
    const form = document.createElement("form")
    form.action = action
    // form.target = "_blank"
    form.method = "POST"
    const csrfField = document.createElement("input")
    csrfField.type = "hidden"
    csrfField.name = "authenticity_token"
    csrfField.value = document.querySelector("[name=csrf-token]").content
    form.appendChild(csrfField)
    const idsField = document.createElement("input")
    idsField.type = "hidden"
    idsField.name = "ids"
    idsField.value = selectedIds
    form.appendChild(idsField)
    const typeField = document.createElement("input")
    typeField.type = "hidden"
    typeField.name = "type"
    typeField.value = type
    form.appendChild(typeField)
    document.body.appendChild(form)
    form.submit()
    const message = type==="pdf" ? "Η εξαγωγή βρίσκεται σε εξέλιξη. Θα ειδοποιηθείτε στο email σας μόλις ολοκληρωθεί." : "Το αρχείο csv θα κατέβει σύντομα"
    notification("notice", message)
  }

  handleSort(event) {
    const currentColumn = this.sortColumnTarget.value
    const thisColumn = event.target.dataset.column
    if ( currentColumn == thisColumn ) {
      this.sortDirectionTarget.value = this.sortDirectionTarget.value == "desc" ? "asc" : "desc"
    } else {
      this.sortColumnTarget.value = thisColumn
      this.sortDirectionTarget.value = "desc"
    }
    this.filtersFormTarget.submit()
  }

  notesEdit(event) {
    let formUrl = event.target.dataset.formUrl
    fetch(formUrl, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken()
      }
    }).then((response) => response.text())
      .then((data) => {
        event.target.hidden = true
        document.getElementById("invoice-notes").innerHTML = data
      })
  }

  informationalTooltipToggle(e) {
    e.target.closest(".row").querySelector(".tooltip-text").classList.toggle("d-none")
  }

  showCancelInvoiceSpinner(e) {
    e.target.closest('.modal-footer').querySelector('.cancel-invoice-spinner').hidden = false
  }

  hideCancelInvoiceSpinner(e) {
    e.target.closest('.modal-footer').querySelector('.cancel-invoice-spinner').hidden = true
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
}
