import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {url: String, employeeUrl: String}
  static targets = ["invoiceSelect", "link"]

  connect() {
    this.updateLink()
  }

  updateLink() {
    const hideElem = document.querySelector(".js-hide-no-selection")
    if (this.invoiceSelectTarget.value === "" || this.invoiceSelectTarget.value === undefined){
      this.linkTarget.href = ""
      this.linkTarget.classList.add("d-none")
      if (hideElem) { hideElem.classList.add("d-none") }
    } else {
      this.linkTarget.classList.remove("d-none")
      if ( this.element.querySelector("select").name.includes("employee") ) {
        this.linkTarget.href = this.employeeUrlValue.replace("replace-with-id", this.invoiceSelectTarget.value)
      } else {
        this.linkTarget.href = this.urlValue.replace("replace-with-id", this.invoiceSelectTarget.value)
      }
      if (hideElem) { hideElem.classList.remove("d-none") }
    }
  }
}
