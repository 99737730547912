import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "percentageAmount", "amount", "deductionType" ]

  connect() {
    this.handleAmountInputs()
  }

  handleAmountInputs() {
    this.percentageAmountTarget.disabled = this.deductionTypeTarget.value != "percentage"
    this.percentageAmountTarget.parentElement.hidden = this.deductionTypeTarget.value != "percentage"
    this.amountTarget.disabled = this.deductionTypeTarget.value != "static"
    this.amountTarget.parentElement.hidden = this.deductionTypeTarget.value != "static"
    document.getElementById('deduction_informational').closest('div').hidden = this.deductionTypeTarget.value === ''
  }

}
