import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  unsavedChangesWindow(e) {
    if ( document.querySelector("body").dataset.unsavedChanges ) {
      e.preventDefault()
      return (e.returnValue = "There are unsaved changes, sure you want to leave?")
    }
  }

  unsavedChanges(e) {
    if ( document.querySelector("body").dataset.unsavedChanges ) {
      if ( !confirm("There are unsaved changes, sure you want to leave?") ) {
        e.preventDefault()
      }
    }
  }
}
