import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["title", "titleForm"]

  showTitleForm() {
    this.titleTarget.hidden = true
    this.titleFormTarget.hidden = false
  }
}
